'use client'

import { HOTEL_SEARCH_ORDER_BY_DEFAULT } from 'core/bookings'
import { isEmpty } from 'lodash'
import { useParams, useRouter, useSearchParams } from 'next/navigation'
import qs from 'qs'
import { formatDate } from 'utils'

const useRoomSearchParams = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const areaId = searchParams?.get('areaId') as string
  const hotelCode = searchParams?.get('hotelCode') as string
  const amenities = searchParams?.get('amenities') as string
  const numberRoomGuest = searchParams?.get('numberRoomGuest') as string
  const type = searchParams?.get('type') as string
  const checkIn = searchParams?.get('checkIn') as string
  const checkOut = searchParams?.get('checkOut') as string
  const orderBy = searchParams?.get('orderBy')
  const defaultOrderBy = orderBy || (HOTEL_SEARCH_ORDER_BY_DEFAULT as string)
  const searchTimestamp = searchParams?.get('searchTimestamp') as string
  const selectedHotelPlanCodes = searchParams?.get('hotelPlanCodes') as string
  const notScrollToSearchResult = searchParams?.get(
    'notScrollToSearchResult',
  ) as string

  const defaultType = (type || 'plan') as 'plan' | 'rooms'
  const defaultDateRange =
    checkIn && checkOut
      ? [
          new Date(`${checkIn}T00:00:00` as string),
          new Date(`${checkOut}T23:59:59` as string),
        ]
      : [null, null]

  const defaultAmenities = !isEmpty(qs.parse(amenities))
    ? qs.parse(amenities)
    : {
        planList: [],
        roomTypeList: [],
      }

  const defaultNumberRoomGuest: any[] | undefined = !isEmpty(
    qs.parse(numberRoomGuest),
  )
    ? Object.values(qs.parse(numberRoomGuest))
    : [
        {
          index: 1,
          numberOfAdult: 1,
          numberOfChildren: 0,
        },
      ]

  const onModifySearchCondition = () => {
    const urlSearchParams = new URLSearchParams()
    urlSearchParams.set('areaId', areaId)
    urlSearchParams.set('hotelCode', hotelCode)
    if (amenities) {
      urlSearchParams.set('amenities', amenities)
    }
    urlSearchParams.set('numberRoomGuest', numberRoomGuest)
    urlSearchParams.set('checkIn', formatDate(defaultDateRange?.[0]))
    urlSearchParams.set('checkOut', formatDate(defaultDateRange?.[1]))
    urlSearchParams.set('type', defaultType)
    urlSearchParams.set('orderBy', defaultOrderBy)
    urlSearchParams.set('searchTimestamp', Date.now().toString())
    if (selectedHotelPlanCodes) {
      urlSearchParams.set('hotelPlanCodes', selectedHotelPlanCodes)
    }
    urlSearchParams.set('notScrollToSearchResult', 'true')
    router.push(`/${locale}?${urlSearchParams.toString()}`)
  }

  const roomSearchParams = {
    areaId: areaId as string,
    hotelCode: hotelCode as string,
    amenities: amenities as string,
    defaultAmenities: defaultAmenities,
    checkIn: checkIn as string,
    checkOut: checkOut as string,
    dateRange: defaultDateRange,
    numberRoomGuest: numberRoomGuest as string,
    defaultNumberRoomGuest,
    defaultType,
    type: type as string,
    orderBy: orderBy as string,
    defaultOrderBy: defaultOrderBy as string,
    searchTimestamp: searchTimestamp as string,
    selectedHotelPlanCodes: selectedHotelPlanCodes as string,
    notScrollToSearchResult: notScrollToSearchResult === 'true',
    onModifySearchCondition,
  }

  return roomSearchParams
}

export default useRoomSearchParams
