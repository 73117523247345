'use client'

import {
  FormAmenitiesSelect,
  FormDateRangePicker,
  FormRadioGroup,
  FormRoomGuestSelect,
} from 'app/components/forms'

import PeopleIcon from 'public/icons/people.svg'
import Select from './Select'
import HotelIcon from 'public/icons/hotel.svg'
import HotelLgIcon from 'public/icons/hotel-lg.svg'
import AddressIcon from 'public/icons/address.svg'
import classNames from 'classnames'
import { Button } from 'app/components/atoms'
import MaxNightWarning from './MaxNightWarning'
import useSearchHotelForm from './useSearchHotelForm'
import { useTranslation } from 'app/i18n/client'
import { SearchHotelFormValues } from './types'
import { AREA_OPTION_ALL, HOTEL_OPTION_ALL } from 'core/bookings'

interface SearchHotelFormValuesProps {
  onSubmit: (values: SearchHotelFormValues) => void
  isSubmiting: boolean
}

const SearchHotelForm: React.FunctionComponent<SearchHotelFormValuesProps> = ({
  isSubmiting,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const {
    hotelId,
    hotelCode,
    selectedAreaId,
    type,
    numberRoomGuest,
    amenities,
    control,
    canSubmit,
    selectedArea,
    areaOptions,
    hotelOptions,
    typeOptions,
    planOptions,
    roomTypeOptions,
    loadingArea,
    loadingHotel,
    watchRoomGuest,
    hotelSearchFilterList,
    hotelCodes,
    onFormSubmit,
    onAreaChanged,
    onHotelChanged,
  } = useSearchHotelForm(onSubmit)

  return (
    <form onSubmit={onFormSubmit}>
      <div className="bg-white px-3 py-4 lg:px-8 lg:py-8 grid grid-cols-2 lg:grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-5 lg:gap-y-6">
        <Select
          placeHolder={t('Select area')}
          label={t('Area')}
          loading={loadingArea}
          options={areaOptions}
          value={selectedAreaId}
          onChanged={onAreaChanged}
          Icon={<AddressIcon className="fill-current" />}
          ButtonIcon={<AddressIcon />}
          selectTitle={t('Select area')}
          columnNumber={2}
        />
        <Select
          loading={loadingHotel}
          placeHolder={t('Select Hotel')}
          label={t('Hotel')}
          options={hotelOptions}
          value={hotelCode}
          onChanged={onHotelChanged}
          Icon={<HotelIcon className="stroke-current" />}
          ButtonIcon={<HotelLgIcon />}
          selectTitle={
            selectedArea && selectedArea?.value !== AREA_OPTION_ALL
              ? t('Hotels in {{areaName}}', {
                  areaName: selectedArea.label,
                })
              : t('Select Hotel')
          }
          isAllAreaOptions={selectedAreaId === AREA_OPTION_ALL}
        />
        <div className="col-span-2">
          <FormDateRangePicker
            name="dateRange"
            control={control}
            roomGuests={watchRoomGuest.map((item: any) => ({
              adults: item.numberOfAdult,
              children: item.numberOfChildren,
            }))}
            hotelId={hotelId}
          />
        </div>
        <div className="col-span-2">
          <FormRoomGuestSelect
            maxRoom={hotelCode === HOTEL_OPTION_ALL ? 1 : undefined}
            name="numberRoomGuest"
            control={control}
            disabled={!hotelCode}
            containerClassName={classNames(
              'border-gray-150 border border-l-2 p-3',
              !hotelCode ? 'border-l-gray-light' : 'border-l-primary',
            )}
            buttonClassName={classNames(
              '!mt-1 text-sm lg:text-lg !bg-transparent !border-none !px-0 !py-0',
              !hotelCode ? 'text-gray-50' : 'text-gray-dark',
            )}
            Icon={
              <div className="text-gray-light lg:text-yellow-50">
                <PeopleIcon
                  className={classNames(
                    'lg:mr-2 fill-current',
                    !hotelCode ? 'text-gray-50' : 'text-primary',
                  )}
                />
              </div>
            }
            defaultValue={numberRoomGuest}
          />
        </div>
        <div className="col-span-2 md:col-span-1 flex items-center">
          <FormRadioGroup
            disabled={hotelCode === HOTEL_OPTION_ALL}
            name="type"
            control={control}
            label={
              <label className="text-xs select-none text-gray-light uppercase">
                {t('Search method')}
              </label>
            }
            align="horizontal"
            defaultValue={type}
            options={typeOptions}
          />
        </div>
        <div className="mt-4 hidden md:block text-base font-medium text-primary text-center space-x-1">
          <MaxNightWarning />
        </div>
        <div className="col-span-2 lg:col-span-3">
          {hotelSearchFilterList && (
            <FormAmenitiesSelect
              key={hotelCodes.join('-')}
              planOptions={planOptions}
              roomTypeOptions={roomTypeOptions}
              name="amenities"
              control={control}
              defaultValue={amenities as any}
            />
          )}
        </div>
        <div className="hidden lg:flex justify-end">
          <Button
            size="sm"
            disabled={!canSubmit}
            loading={isSubmiting}
            className="w-52 h-13 !text-base font-bold uppercase"
            type="submit"
          >
            {t('Search')}
          </Button>
        </div>
      </div>
      <div className="w-full text-right lg:hidden">
        <Button
          size="sm"
          disabled={!canSubmit}
          loading={isSubmiting}
          className="!w-full h-13 !text-base font-bold uppercase"
          type="submit"
        >
          {t('Search')}
        </Button>
        <div className="md:hidden mt-4 text-base font-medium text-primary text-center space-x-1 text-sm">
          <MaxNightWarning />
        </div>
      </div>
    </form>
  )
}

export default SearchHotelForm
