'use client'

import classNames from 'classnames'
import { Button } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import { formatToYen } from 'utils'
import { useParams } from 'next/navigation'

interface MultipleBookingBarProps {
  totalRooms?: number
  totalPriceBeforeTax?: number
  totalPrice?: number
  totalPeople?: number
  isViewingDetailMultiple: boolean
  isBookingMultiple: boolean
  onViewDetailMultiple: () => void
  onReserveMultiple: () => void
}

const MultipleBookingBar: React.FC<MultipleBookingBarProps> = ({
  totalRooms,
  totalPriceBeforeTax,
  totalPrice,
  totalPeople,
  isViewingDetailMultiple,
  isBookingMultiple,
  onViewDetailMultiple,
  onReserveMultiple,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return (
    <div className="fixed inset-x-0 bottom-0 bg-white z-[6] border border-primary-light">
      <div className="w-full lg:w-auto lg:max-w-7xl lg:mx-auto px-5 lg:px-8 lg:px-0 lg:flex items-center justify-between py-4 lg:py-5">
        {!totalRooms || totalRooms === 0 ? (
          <div className="flex space-x-6 justify-between lg:justify-start">
            <div className="flex space-x-2 items-end whitespace-nowrap">
              <div className="text-[28px] lg:text-[32px]">¥ -</div>
              <div className="text-xxs lg:text-xs text-gray-light mb-2 whitespace-nowrap">
                ({t('Excluding Tax')} ¥-)
              </div>
            </div>
            <div className="text-xxs lg:text-sm text-red-400 lg:bg-red-400 lg:bg-opacity-10 p-3">
              {t('Select the room type, number of rooms, and number of people')}
            </div>
          </div>
        ) : (
          <div className="flex space-x-6 justify-between lg:justify-start">
            <div
              className={classNames(
                locale === 'en'
                  ? 'lg:flex lg:space-x-2 lg:items-end'
                  : 'flex space-x-2 items-end',
              )}
            >
              <div className="text-[28px] lg:text-[32px] whitespace-nowrap">
                {formatToYen(totalPrice)}
              </div>
              <div className="text-xxs lg:text-xs text-gray-light mb-2 whitespace-nowrap">
                ({t('Excluding Tax')} {formatToYen(totalPriceBeforeTax)})
              </div>
            </div>
            <div
              className={classNames(
                'bg-[#F6FBFD] px-3 text-xxs lg:text-xs flex space-x-1',
                locale === 'en' ? 'items-center lg:items-end' : 'items-end',
              )}
            >
              <div
                className={classNames(
                  locale === 'en' ? 'mb-0 lg:mb-2' : 'mb-2',
                )}
              >
                {t('Selected')}:
              </div>
              <div className="text-[24px] mb-3 text-primary-dark">
                {totalRooms}
              </div>
              <div
                className={classNames(
                  locale === 'en' ? 'mb-0 lg:mb-2' : 'mb-2',
                )}
              >
                {t('Rooms')}
              </div>
              <div className="text-[24px] mb-3 text-primary-dark">
                {totalPeople}
              </div>
              <div
                className={classNames(
                  locale === 'en' ? 'mb-0 lg:mb-2' : 'mb-2',
                )}
              >
                {t('People')}
              </div>
            </div>
          </div>
        )}
        <div className="mt-2 lg:mt-0 flex space-x-4">
          <div className="flex-1 lg:flex-auto lg:w-[160px]">
            <Button
              disable={totalRooms === 0}
              size="sm"
              loading={isViewingDetailMultiple}
              className={classNames(
                '!py-2.5 !px-10 !text-xs !bg-white !border w-full',
                totalRooms === 0
                  ? '!text-gray-150 !border-gray-150 cursor-auto'
                  : '!text-primary !border-primary',
              )}
              loadingClassName="!text-white"
              loadingSpinerClassName="!text-gray-500"
              onClick={() => onViewDetailMultiple()}
            >
              {t('Details')}
            </Button>
          </div>
          <div className="flex-1 lg:flex-auto lg:w-[160px]">
            <Button
              disabled={totalRooms === 0}
              size="sm"
              loading={isBookingMultiple}
              className="!py-2.5 !px-10 !text-xs w-full"
              onClick={() => onReserveMultiple()}
            >
              {t('Book Now')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultipleBookingBar
