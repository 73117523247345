import React from 'react'
import { Controller } from 'react-hook-form'
import { Input } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import { Control } from 'react-hook-form'
import { InputProps } from 'app/components/atoms/Input'

interface FormInputProps extends InputProps {
  control?: Control<any>
  name: string
  defaultValue?: string
  error?: any
  controllerProps?: any
}

const FormInput = ({
  controllerProps,
  control,
  cardInputType,
  name,
  defaultValue,
  error,
  ...rest
}: FormInputProps) => {
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <Input
          {...rest}
          name={name}
          value={value}
          cardInputType={cardInputType}
          onBlur={rest?.onBlur || onBlur}
          onChangeText={onChange}
          isError={rest?.isError || !!error}
          errorMessage={rest?.errorMessage || t(error?.message)}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      {...controllerProps}
    />
  )
}

export default FormInput
