import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'
import { QUERY_HOTEL_PLAN_LIST_KEY, fetchHotelPlans } from './fetchHotelPlans'

const useQueryHotelPlanList = (hotelCode: string) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return useQuery({
    queryKey: [
      QUERY_HOTEL_PLAN_LIST_KEY,
      {
        hotelCode,
        locale,
      },
    ],
    queryFn: fetchHotelPlans(),
    enabled: !!hotelCode,
  })
}

export default useQueryHotelPlanList
