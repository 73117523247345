'use client'
import CrossIcon from 'public/icons/cross.svg'
import { useTranslation } from 'app/i18n/client'

interface SelectedPlanProps {
  selectedPlanName?: string
  onRemoveSelectedPlan: () => void
}

const SelectedPlan: React.FC<SelectedPlanProps> = ({
  selectedPlanName,
  onRemoveSelectedPlan,
}) => {
  const { t } = useTranslation()

  return (
    <div className="mt-8 flex items-center jusfify-between bg-primary px-8 py-6">
      <div className="flex-1">
        <label className="text-xs text-primary-light">
          {t('Selected Plan')}
        </label>
        <div className="mt-3 text-lg text-white pr-4">{selectedPlanName}</div>
      </div>
      <div className="cursor-pointer p-4" onClick={onRemoveSelectedPlan}>
        <CrossIcon />
      </div>
    </div>
  )
}

export default SelectedPlan
