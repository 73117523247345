import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../FormInput'
import FormSelect from '../FormSelect'
import FormCheckbox from '../FormCheckbox'
import FormDatePicker from '../FormDatePicker'
import FormDateRangePicker from '../FormDateRangePicker'
import FormDateTimePicker from '../FormDateTimePicker'
import * as yup from 'yup'
import {
  Mode,
  UseFormSetError,
  UseFormSetValue,
  UseFormClearErrors,
} from 'react-hook-form'

const supportedTypes = [
  FormInput.name,
  FormSelect.name,
  FormCheckbox.name,
  FormDatePicker.name,
  FormDateRangePicker.name,
  FormDateTimePicker.name,
]

interface FormProps {
  schema: yup.AnyObjectSchema
  mode?: Mode
  onSubmit: (
    values: any,
    setError?: UseFormSetError<any>,
    setValue?: UseFormSetValue<any>,
    clearErrors?: UseFormClearErrors<any>,
  ) => void
  className?: string
  children: React.ReactNode
}

const Form: React.FunctionComponent<FormProps> = ({
  children,
  schema,
  mode = 'onSubmit',
  className,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode,
    resolver: yupResolver(schema),
  })

  return (
    <form
      onSubmit={handleSubmit((values) =>
        onSubmit(values, setError, setValue, clearErrors),
      )}
      className={className}
    >
      {(Array.isArray(children) ? [...children] : [children]).map((child) => {
        return !child?.type?.name || !supportedTypes.includes(child?.type?.name)
          ? child
          : React.createElement(child.type, {
              ...{
                ...child.props,
                control,
                key: child.props.name,
                error: errors[child.props.name],
              },
            })
      })}
    </form>
  )
}

export default Form
