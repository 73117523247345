import { Hotel } from 'tokyu-hooks'
import { getClientSession } from 'lib/client/clientSession'
import { HOTEL_OPTION_ALL, toPlanListItem } from '../../../core/bookings'

export const QUERY_HOTEL_PLAN_LIST_KEY = 'QUERY_HOTEL_PLAN_LIST_KEY'

export const fetchHotelPlans =
  (clientSession?: string) =>
  async ({ queryKey }: { queryKey: any[] }) => {
    const [_key, { hotelCode, locale }] = queryKey

    const res =
      hotelCode === HOTEL_OPTION_ALL
        ? await Hotel.listPlans({
            baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
            clientSession: clientSession || (await getClientSession()),
            hotelBrand: process.env.NEXT_PUBLIC_TRIPLA_HOTEL_BRAND as string,
            locale,
          })
        : await Hotel.listHotelPlans({
            baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
            clientSession: clientSession || (await getClientSession()),
            hotelCode,
            locale,
          })

    return res?.data?.data?.map(toPlanListItem) || null
  }
