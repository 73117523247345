import React from 'react'
import { Controller } from 'react-hook-form'
import { RoomGuestSelect } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import { Control } from 'react-hook-form'
import {
  RoomGuestInfo,
  RoomGuestSelectProps,
} from 'app/components/atoms/RoomGuestSelect'

interface FormRoomGuestSelectProps extends RoomGuestSelectProps {
  control?: Control<any>
  name: string
  defaultValue?: RoomGuestInfo[]
  error?: any
  controllerProps?: any
}

const FormRoomGuestSelect = ({
  controllerProps,
  control,
  name,
  defaultValue,
  error,
  ...rest
}: FormRoomGuestSelectProps) => {
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <RoomGuestSelect
          {...rest}
          value={value}
          onChange={onChange}
          isError={!!error}
          errorMessage={t(error?.message)}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      {...controllerProps}
    />
  )
}

export default FormRoomGuestSelect
