/* eslint-disable prettier/prettier */
import { Trans } from 'react-i18next'
import { INQUIRY_LONG_STAY_LINK } from 'utils/constants'

const MaxNightWarning: React.FC = () => {
  return (
    <Trans i18nKey="* Click <1>here</1> for inquiries for 32 nights or more">
      * Click <a
        href={INQUIRY_LONG_STAY_LINK}
        target="blank"
        className="font-bold underline"
      >
        here
      </a> for inquiries for 32 nights or more
    </Trans>
  )
}

export default MaxNightWarning
