'use client'
import { useQuery } from '@tanstack/react-query'

import { useParams } from 'next/navigation'
import fetchHotels, { QUERY_HOTEL_LIST_KEY } from './fetchHotels'

const useQueryHotelList = (areaId?: string | null) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return useQuery({
    queryKey: [QUERY_HOTEL_LIST_KEY, { areaId, locale }],
    queryFn: fetchHotels(),
  })
}

export default useQueryHotelList
