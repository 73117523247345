import { useQuery } from '@tanstack/react-query'
import { QUERY_AREA_LIST_KEY, fetchAreas } from './fetchAreas'

const useQueryAreaList = () => {
  return useQuery({
    queryKey: [QUERY_AREA_LIST_KEY],
    queryFn: fetchAreas,
  })
}

export default useQueryAreaList
