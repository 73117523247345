import { useState, useEffect } from 'react'

import { useParams, useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { getJapanTimeZoneToday } from 'utils/toJapanTimeZone'

import useQueryAreaList from 'app/hooks/useQueryAreaList'
import useQueryHotelList from 'app/hooks/useQueryHotelList'
import useQueryHotelSearchFilterList from 'app/hooks/useQueryHotelSearchFilterList'
import { SearchHotelFormValues } from './types'
import { AREA_OPTION_ALL, HOTEL_OPTION_ALL } from 'core/bookings'
import useRoomSearchParams from 'app/hooks/useRoomSearchParams'
import { useTranslation } from 'app/i18n/client'

const schema = yup.object().shape({
  dateRange: yup.array().of(yup.date().required()).required(),
  numberRoomGuest: yup
    .array()
    .of(
      yup.object().shape({
        index: yup.number().required(),
        numberOfAdult: yup.number().min(1),
        numberOfChildren: yup.number().min(0),
      }),
    )
    .min(1)
    .required(),
  type: yup.string().oneOf(['plan', 'rooms']),
  amenities: yup.object().shape({
    planList: yup.array().of(yup.string()),
    roomTypeList: yup.array().of(yup.string()),
  }),
})

const useSearchHotelForm = (
  onSubmit: (values: SearchHotelFormValues) => void,
) => {
  const { t } = useTranslation()
  const router = useRouter()

  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const {
    areaId,
    hotelCode,
    dateRange,
    defaultNumberRoomGuest: numberRoomGuest,
    defaultType: type,
    defaultAmenities: amenities,
    searchTimestamp,
  } = useRoomSearchParams()

  const selectedAreaId = areaId || AREA_OPTION_ALL
  const [loadingArea, setLoadingArea] = useState(false)
  const [loadingHotel, setLoadingHotel] = useState(false)

  const { data: areaList } = useQueryAreaList()

  const { data: hotelList } = useQueryHotelList(
    (selectedAreaId !== AREA_OPTION_ALL ? selectedAreaId : null) as
      | string
      | undefined
      | null,
  )

  const hotelId =
    (hotelCode as string) === HOTEL_OPTION_ALL
      ? undefined
      : hotelList?.find((item: any) => item.triplabotCode === hotelCode)?.id

  const hotelCodes: string[] | undefined =
    (hotelCode as string) === HOTEL_OPTION_ALL
      ? hotelList?.map((item: any) => item.triplabotCode) || []
      : [hotelCode]

  const { data: hotelSearchFilterList } =
    useQueryHotelSearchFilterList(hotelCodes)

  const areaOptions = areaList
    ? [
        {
          label: t('All Areas'),
          value: AREA_OPTION_ALL,
        },
        ...areaList?.map((option) => ({
          label:
            option?.translations?.find((n) => n.locale === locale)?.name || '',
          value: option?.id?.toString(),
        })),
      ]
    : []

  const hotelOptions = (
    hotelList
      ? [
          selectedAreaId !== AREA_OPTION_ALL
            ? {
                label: t('All Hotels'),
                value: hotelList?.length > 1 ? HOTEL_OPTION_ALL : undefined,
                groupName: hotelList?.[0]?.areaName,
              }
            : undefined,
          ...hotelList?.map((option: any) => ({
            label: option?.name,
            value: option?.triplabotCode,
            groupName: option?.areaName,
          })),
        ].filter((item) => !!item?.value)
      : []
  ) as Array<{
    label: string
    value: any
    groupName?: string
  }>

  const typeOptions = [
    {
      label: t('Plans'),
      value: 'plan',
    },
    {
      label: t('Room method'),
      value: 'rooms',
    },
  ]

  const planOptions =
    hotelSearchFilterList
      ?.filter((item) => item.type === 'plan')
      ?.map((item) => ({
        label: item.name,
        value: item.id.toString(),
      })) || []

  const roomTypeOptions =
    hotelSearchFilterList
      ?.filter((item) => item.type === 'room')
      ?.map((item) => ({
        label: item.name,
        value: item.id.toString(),
      })) || []

  useEffect(() => {
    setLoadingArea(false)
  }, [selectedAreaId])

  useEffect(() => {
    if (hotelCode && !searchTimestamp) {
      setValue('amenities', {
        planList: [],
        roomTypeList: [],
      })
    }
  }, [hotelCode, searchTimestamp])

  const onAreaChanged = (value: string) => {
    if (value === selectedAreaId) return

    setLoadingArea(true)
    router.push(`/${locale}?areaId=${value}`)
  }

  const onHotelChanged = (value: string) => {
    if (value === hotelCode) return

    setLoadingHotel(true)
    router.push(`/${locale}?areaId=${selectedAreaId}&hotelCode=${value}`)
  }

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      areaId,
      hotelCode,
      dateRange:
        dateRange?.[0] === null && dateRange?.[1] === null
          ? [
              getJapanTimeZoneToday().toDate(),
              getJapanTimeZoneToday().add(1, 'day').toDate(),
            ]
          : dateRange,
      numberRoomGuest,
      type,
      amenities,
    },
  })

  const onFormSubmit = handleSubmit((values: any) => {
    onSubmit({
      areaId: selectedAreaId as string,
      hotelCode: hotelCode as string,
      amenities: values.amenities,
      numberRoomGuest: values?.numberRoomGuest,
      dateRange: values.dateRange,
      type: values.type,
    })
  })

  const watchRoomGuest = watch('numberRoomGuest')

  useEffect(() => {
    setLoadingHotel(false)

    if (hotelCode === HOTEL_OPTION_ALL) {
      setValue('type', 'rooms')
      setValue(
        'numberRoomGuest',
        [
          {
            index: 1,
            numberOfAdult: 1,
            numberOfChildren: 0,
          },
        ],
        { shouldValidate: true },
      )
    }
  }, [hotelCode])

  const canSubmit = isValid && selectedAreaId && hotelCode

  const selectedArea = areaOptions?.find(
    (item) => item.value === selectedAreaId,
  )

  return {
    hotelId,
    hotelCode,
    selectedAreaId,
    type,
    numberRoomGuest,
    amenities,
    control,
    canSubmit,
    selectedArea,
    areaOptions,
    hotelOptions,
    typeOptions,
    planOptions,
    roomTypeOptions,
    loadingArea,
    loadingHotel,
    watchRoomGuest,
    hotelSearchFilterList,
    hotelCodes,
    onFormSubmit,
    onAreaChanged,
    onHotelChanged,
  }
}

export default useSearchHotelForm
