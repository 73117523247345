import React from 'react'
import { Controller } from 'react-hook-form'
import { AmenitiesSelect } from 'app/components/atoms'
import { AmenitiesSelectProps } from 'app/components/atoms/AmenitiesSelect'
import { Control } from 'react-hook-form'

interface FormAmenitiesSelectProps extends AmenitiesSelectProps {
  control?: Control
  name: string
  defaultValue?: {
    planList: string[]
    roomTypeList: string[]
  }
  error?: any
  controllerProps?: any
}

const FormAmenitiesSelect = ({
  controllerProps,
  control,
  name,
  planOptions,
  roomTypeOptions,
  defaultValue,
  ...rest
}: FormAmenitiesSelectProps) => {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => {
        const onCheck =
          (type: 'planList' | 'roomTypeList') => (name: string) => {
            if (!value) return

            if (value[type]?.includes(name)) {
              onChange({
                ...value,
                [type]:
                  value[type]?.filter((item: string) => item !== name) || [],
              })
              return
            }

            onChange({
              ...value,
              [type]: [...(value[type] || []), name],
            })
          }

        const onPlanCheck = onCheck('planList')
        const onRoomTypeCheck = onCheck('roomTypeList')

        return (
          <AmenitiesSelect
            {...rest}
            planOptions={planOptions?.map((option) => ({
              ...option,
              checked: value?.planList?.includes(option.value),
            }))}
            roomTypeOptions={roomTypeOptions?.map((option) => ({
              ...option,
              checked: value?.roomTypeList?.includes(option.value),
            }))}
            onPlanCheck={onPlanCheck}
            onRoomTypeCheck={onRoomTypeCheck}
          />
        )
      }}
      name={name}
      defaultValue={defaultValue}
      {...controllerProps}
    />
  )
}

export default FormAmenitiesSelect
