'use client'

import CheckIcon from 'public/icons/check.svg'
import classNames from 'classnames'

interface SelectItemProps {
  option: {
    label: string
    value: any
  }
  selectedValue: string
  onSelect: (value: any) => void
  Icon: React.ReactNode
}

const SelectItem: React.FC<SelectItemProps> = ({
  option,
  selectedValue,
  onSelect,
  Icon,
}) => {
  const isSelected = selectedValue === option.value

  return (
    <div
      className={classNames(
        'flex space-x-2 items-center text-sm cursor-pointer',
      )}
      onClick={() => onSelect(option.value)}
    >
      <div className={classNames(isSelected ? 'text-primary' : 'text-gray-50')}>
        {Icon}
      </div>
      <div
        className={classNames(
          'hover:text-primary text-sm text-left',
          isSelected && 'text-primary',
        )}
      >
        {option.label}
      </div>
      {isSelected && (
        <CheckIcon className={classNames('text-primary stroke-current')} />
      )}
    </div>
  )
}

export default SelectItem
