'use client'

import classNames from 'classnames'
import { useTranslation } from 'app/i18n/client'
import { formatToYen } from 'utils'
import ZoomOutSmIcon from 'public/icons/zoom-out-sm.svg'
import CopySmIcon from 'public/icons/copy-sm.svg'
import Image from 'next/image'
import HouseIcon from 'public/icons/house-lg.svg'
import UserIcon from 'public/icons/user.svg'
import KidIcon from 'public/icons/kid.svg'
import { Button, Checkbox, Select } from 'app/components/atoms'
import { SearchResultGroupItemProps } from '..'
import { useParams } from 'next/navigation'
import { getRoomOptions } from 'core/bookings'

const GroupItemSm: React.FC<SearchResultGroupItemProps> = ({
  isMultipleSelect,
  canRegularBook,
  groupItem,
  showSubTitle,
  searchMethod,
  onSelectGroupItem,
  onGroupItemImageClick,
  onChangeRoomNumber,
  onViewDetailSingle,
  onReserveSingle,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return (
    <div className="lg:hidden border-l-2 border-primary-dark pl-4">
      {isMultipleSelect && (
        <div className="w-fit mb-2 border border-primary-light py-2 px-3 bg-[#F6FBFD]">
          <Checkbox
            name={`room-plan-${groupItem.id}`}
            containerSize="md"
            label={
              searchMethod === 'plan' ? t('Select Rooms') : t('Select plan')
            }
            textClassName="!text-sm"
            inputWraperClassName="!items-center"
            checked={groupItem.selected}
            onChanged={(_) => onSelectGroupItem()}
          />
        </div>
      )}
      <div className="flex space-x-4">
        <div>
          <div className="flex-0 w-[112px] h-[63px] bg-gray-100 flex-0 relative">
            {groupItem?.images?.[0] && (
              <>
                <Image
                  alt={t('Room Image')}
                  src={groupItem?.images?.[0]}
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'fill',
                  }}
                />
                <div
                  className="absolute cursor-pointer flex items-center justify-center bottom-1 right-1 w-5 h-5 bg-white rounded-full"
                  onClick={() => onGroupItemImageClick()}
                >
                  {searchMethod === 'plan' ? <ZoomOutSmIcon /> : <CopySmIcon />}
                </div>
              </>
            )}
          </div>
          {searchMethod === 'rooms' && groupItem?.recommended && (
            <div className="mt-2 flex items-center justify-center">
              <div className="border bg-white py-1.5 px-2 text-gray-dark text-xxs uppercase">
                {t('recommended')}
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 space-y-3">
          <div className="font-medium">{groupItem?.name}</div>
          <div className="text-sm">
            {showSubTitle ? groupItem?.subTitle : ''}
          </div>
          {!isMultipleSelect && (
            <div className="text-right pr-2">
              <Button
                loading={groupItem.isViewingDetail}
                size="sm"
                loadingClassName="!text-primary-light"
                loadingSpinerClassName="!text-primary"
                className="!bg-primary-light !text-primary !text-xs !px-4 !py-2.5 !w-[96px]"
                onClick={() => onViewDetailSingle()}
              >
                {t('Details')}
              </Button>
            </div>
          )}
        </div>
      </div>
      {!isMultipleSelect && (
        <div className="mt-4 border-y border-gray-150 divide-y divide-gray-150">
          <div className="flex justify-between p-2">
            <div className="flex space-x-2 items-center pr-2">
              <div
                className={classNames(
                  'text-xs text-gray-light flex flex-wrap',
                  locale === 'en' ? 'max-w-[110px]' : 'max-w-[95px]',
                )}
              >
                <div>{t('Regular price')}</div>
                <div>({t('Including Tax')})</div>
              </div>
              <div className="text-xl text-gray-dark whitespace-nowrap flex-0">
                {formatToYen(groupItem?.regularPrice * groupItem?.roomNumber)}
              </div>
            </div>
            <div className="flex-0 items-center flex">
              <Button
                className="!text-xs !px-4 !py-2.5 !w-[96px]"
                size="sm"
                loading={groupItem.isBookingRegular}
                disabled={!canRegularBook}
                onClick={() => {
                  onReserveSingle('regular')
                }}
              >
                {t('Book now')}
              </Button>
            </div>
          </div>
          <div className="flex justify-between p-2">
            <div className="flex space-x-2 items-center pr-2">
              <div
                className={classNames(
                  'text-xs text-primary-dark flex flex-wrap',
                  locale === 'en' ? 'max-w-[110px]' : 'max-w-[95px]',
                )}
              >
                <div>{t('Membership price')}</div>
                <div>({t('Including Tax')})</div>
              </div>
              <div className="text-[24px] text-primary-dark whitespace-nowrap">
                {formatToYen(
                  groupItem?.membershipPrice * groupItem?.roomNumber,
                )}
              </div>
            </div>
            <div className="flex-0 items-center flex">
              <Button
                className="!text-xs !px-4 !py-2.5 !w-[96px]"
                size="sm"
                loading={groupItem.isBookingMembership}
                onClick={() => {
                  onReserveSingle('membership')
                }}
              >
                {t('Book now')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isMultipleSelect && (
        <>
          <div className="mt-4 flex divide-x divide-gray-150">
            <div className="flex-1">
              <div
                className={classNames(
                  'text-sm text-gray-light flex',
                  locale === 'en' ? 'flex-col' : 'space-x-1',
                )}
              >
                <div>{t('Regular price')}</div>
                <div>({t('Including Tax')})</div>
              </div>
              <div className="text-[26px]">
                {formatToYen(groupItem?.regularPrice * groupItem?.roomNumber)}
              </div>
            </div>
            <div className="flex-1 pl-4">
              <div
                className={classNames(
                  'text-sm text-primary-dark flex',
                  locale === 'en' ? 'flex-col' : 'space-x-1',
                )}
              >
                <div>{t('Membership price')}</div>
                <div>({t('Including Tax')})</div>
              </div>
              <div className="text-[26px] text-primary-dark">
                {formatToYen(
                  groupItem?.membershipPrice * groupItem?.roomNumber,
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 flex w-full">
            <div className="flex items-center space-x-2">
              <div>
                <HouseIcon />
              </div>
              <Select
                containerClassName="w-[80px] !bg-white"
                buttonClassName="!py-2.5 !bg-white"
                placeHolder={t('Select')}
                options={getRoomOptions(groupItem.maxRooms)}
                value={groupItem.roomNumber}
                onChanged={(value) => onChangeRoomNumber(Number(value))}
                optionListClassName="w-[130px] min-w-full"
              />
            </div>
            <div className="ml-3 flex items-center space-x-2 space-x-2">
              <UserIcon />
              <div className="text-sm text-gray-light">{t('Adult')}</div>
              <div className="">{groupItem.adultNumber}</div>
            </div>
            <div className="border-l border-gray-150 ml-4 pl-4 flex items-center space-x-2 space-x-2">
              <KidIcon />
              <div className="text-sm text-gray-light">{t('Child')}</div>
              <div className="">{groupItem.childrenNumber}</div>
            </div>
          </div>
          <div className="mt-2 w-full text-xxs text-gray-light p-3 bg-[#FAFAFA]">
            {t(
              'Children 6 years old or over will be charged the same fee as adults(excluding some plans).',
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default GroupItemSm
