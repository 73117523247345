'use client'

import classNames from 'classnames'
import Header from './Header'
import useSearchResult from './useSearchResult'
import SearchResultGroupComp from './SearchResultGroup'
import DetailModal from './DetailModal'
import MultipleBookingBar from './MultileBookingBar'
import { useTranslation } from 'app/i18n/client'

interface SearchResultProps {
  isSearching: boolean
  isOrdering: boolean
  isRemoveSelectedPlan: boolean
  onChangeOrder: (orderBy: string) => void
}

const SearchResult: React.FC<SearchResultProps> = ({
  isSearching,
  isOrdering,
  isRemoveSelectedPlan,
  onChangeOrder,
}) => {
  const { t } = useTranslation()

  const {
    groupList,
    totalRooms,
    totalPeople,
    totalPrice,
    totalPriceBeforeTax,
    isViewingDetailMultiple,
    isBookingMultiple,
    canRegularBook,
    isAuthenticated,
    selectedGroup,
    selectedGroupItem,
    searchMethod,
    searchTimestamp,
    orderBy,
    isLoading,
    isError,
    shoudldSwallowError,
    isMultipleSelect,
    onSelectGroup,
    onSelectGroupItem,
    onUnselectGroup,
    onUnselectGroupItem,
    onChangeRoomNumber,
    onSelect,
    onViewDetailSingle,
    onViewDetailMultiple,
    onReserveSingle,
    onReserveMultiple,
    onDisplayAllItems,
    onDisplayLessItems,
  } = useSearchResult()

  const showLoadingSkeleton =
    isSearching || isOrdering || isLoading || isRemoveSelectedPlan
  const showError =
    !(isSearching || isOrdering || isRemoveSelectedPlan) &&
    isError &&
    !shoudldSwallowError
  const showSeachResult =
    !isSearching &&
    !isOrdering &&
    !isRemoveSelectedPlan &&
    !showError &&
    searchTimestamp &&
    !!searchMethod

  const showSearchResultHeader =
    showSeachResult &&
    groupList &&
    groupList?.length > 0 &&
    !showLoadingSkeleton

  return (
    <div>
      {showSearchResultHeader && (
        <Header
          searchMethod={searchMethod}
          totalResult={groupList?.length}
          orderBy={orderBy}
          isOrdering={isOrdering}
          onChangeOrder={onChangeOrder}
        />
      )}
      <div className={classNames(isMultipleSelect ? 'mt-4' : 'mt-8')}>
        {showLoadingSkeleton && (
          <div className="mt-8 space-y-8">
            {Array(10)
              .fill(0)
              .map((_, index: number) => (
                <div
                  key={`search-skeleton-${index}`}
                  className="h-59 w-full bg-gray-100 bg-opacity-70 animate-pulse"
                />
              ))}
          </div>
        )}
        {showError && (
          <div className="mt-32 w-full text-center text-lg font-lora text-error">
            {t('There are something wrong')}
          </div>
        )}
        {showSeachResult && (!groupList || groupList?.length === 0) && (
          <div className="mt-32 w-full text-center text-lg font-lora text-gray-50">
            {t('No result found')}
          </div>
        )}
        {showSeachResult && groupList && groupList?.length > 0 && (
          <div className="space-y-6">
            {groupList?.map((group) => (
              <SearchResultGroupComp
                key={group.code}
                group={group}
                isMultipleSelect={isMultipleSelect}
                canRegularBook={canRegularBook}
                searchMethod={searchMethod}
                onImageClick={onSelectGroup(group)}
                onGroupItemImageClick={onSelectGroupItem}
                onDisplayAllItems={onDisplayAllItems(group.code)}
                onDisplayLessItems={onDisplayLessItems(group.code)}
                onSelectGroupItem={onSelect(group.code)}
                onChangeRoomNumber={onChangeRoomNumber(group.code)}
                onViewDetailSingle={onViewDetailSingle(group.code)}
                onReserveSingle={onReserveSingle(group.code)}
              />
            ))}
          </div>
        )}
        {isMultipleSelect && showSearchResultHeader && (
          <MultipleBookingBar
            totalRooms={totalRooms}
            totalPriceBeforeTax={totalPriceBeforeTax}
            totalPrice={totalPrice}
            totalPeople={totalPeople}
            isViewingDetailMultiple={isViewingDetailMultiple}
            isBookingMultiple={isBookingMultiple}
            onViewDetailMultiple={onViewDetailMultiple}
            onReserveMultiple={onReserveMultiple(
              isAuthenticated ? 'membership' : 'regular',
            )}
          />
        )}
        {selectedGroup && (
          <DetailModal
            open={!!selectedGroup}
            name={selectedGroup?.name}
            description={
              searchMethod === 'plan' ? selectedGroup?.subTitle : undefined
            }
            containerClassNames={
              searchMethod === 'plan'
                ? 'h-3/4 lg:h-auto max-h-full lg:max-h-4/5'
                : 'lg:max-h-4/5'
            }
            images={selectedGroup?.images}
            onClose={onUnselectGroup}
          />
        )}
        {selectedGroupItem && (
          <DetailModal
            open={!!selectedGroupItem}
            name={selectedGroupItem?.name}
            description={
              searchMethod === 'rooms' ? selectedGroupItem?.subTitle : undefined
            }
            containerClassNames={
              searchMethod === 'rooms'
                ? 'h-3/4 lg:h-auto max-h-full lg:max-h-4/5'
                : 'lg:max-h-4/5'
            }
            images={selectedGroupItem?.images}
            onClose={onUnselectGroupItem}
          />
        )}
      </div>
    </div>
  )
}

export default SearchResult
