import { Hotel } from 'tokyu-hooks'

import { getClientSession } from 'lib/client/clientSession'

import { isEmpty } from 'lodash'
import qs from 'qs'
import {
  HOTEL_SEARCH_ORDER_BY_DEFAULT,
  toSearchResultModel,
} from 'core/bookings'

export const QUERY_AVAILABILITY_SEARCH = 'QUERY_AVAILABILITY_SEARCH'

export const fetchAvailabilitySearch =
  (clientSession?: string) =>
  async ({ queryKey }: { queryKey: any[] }) => {
    const [
      _key,
      {
        hotelCodes,
        amenities,
        numberRoomGuest,
        type,
        orderBy,
        checkIn,
        checkOut,
        roomPlanCodes,
        hotelPlanCodes,
        locale,
      },
    ] = queryKey

    if (!hotelCodes || !numberRoomGuest || !type || !checkIn || !checkOut) {
      return null
    }

    const defaultAmenities = !isEmpty(qs.parse(amenities))
      ? qs.parse(amenities)
      : {
          planList: [],
          roomTypeList: [],
        }

    const defaultNumberRoomGuest = !isEmpty(qs.parse(numberRoomGuest))
      ? Object.values(qs.parse(numberRoomGuest))
      : undefined
    const res = await Hotel.searchAvailability({
      baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
      clientSession: clientSession || (await getClientSession()),
      hotelCodes: hotelCodes as string[],
      rooms:
        defaultNumberRoomGuest?.map((room: any) => ({
          checkin_date: checkIn,
          checkout_date: checkOut,
          adults: room?.numberOfAdult,
          children: room?.numberOfChildren,
        })) || [],
      type: type || 'plan',
      order: orderBy || HOTEL_SEARCH_ORDER_BY_DEFAULT,
      room_search_filters: [
        ...(defaultAmenities?.planList || []),
        ...(defaultAmenities?.roomTypeList || []),
      ],
      room_plan_codes: roomPlanCodes,
      hotel_plan_codes: hotelPlanCodes?.filter((x: any) => !!x),
      locale,
    })

    return toSearchResultModel(res?.data)
  }
