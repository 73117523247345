'use client'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'
import {
  QUERY_AVAILABILITY_SEARCH,
  fetchAvailabilitySearch,
} from './fetchAvailabilitySearch'

const useQueryAvailabilitySearch = (
  hotelCodes: string[],
  checkIn: string,
  checkOut: string,
  amenities: string,
  numberRoomGuest: string,
  type: string,
  orderBy: string,
  roomPlanCodes?: string[] | null,
  hotelPlanCodes?: string[] | null,
  enabled?: boolean,
) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return useQuery({
    queryKey: [
      QUERY_AVAILABILITY_SEARCH,
      {
        hotelCodes,
        amenities,
        numberRoomGuest,
        checkIn,
        checkOut,
        type,
        orderBy,
        roomPlanCodes,
        hotelPlanCodes: hotelPlanCodes?.filter((x: any) => x) || null,
        locale,
      },
    ],
    queryFn: fetchAvailabilitySearch(),
    enabled,
  })
}

export default useQueryAvailabilitySearch
