import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'
import {
  QUERY_SEARCH_FILTER_LIST,
  fetchHotelSearchFilterList,
} from './fetchHotelSearchFilterList'

const useQueryHotelSearchFilterList = (hotelCodes?: string[]) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const filteredHotelCodes = hotelCodes?.filter((code: any) => code)

  return useQuery({
    queryKey: [
      QUERY_SEARCH_FILTER_LIST,
      {
        hotelCodes: filteredHotelCodes,
        locale: locale as string,
      },
    ],
    queryFn: fetchHotelSearchFilterList(),
    enabled: filteredHotelCodes && filteredHotelCodes.length > 0,
  })
}

export default useQueryHotelSearchFilterList
