'use client'

import { SearchResultGroupItem } from '../../types'
import GroupItemLg from './GroupItemLg'
import GroupItemSm from './GroupItemSm'

export interface SearchResultGroupItemProps {
  isMultipleSelect?: boolean
  canRegularBook: boolean
  groupItem: SearchResultGroupItem
  showSubTitle: boolean
  searchMethod: 'plan' | 'rooms'
  onSelectGroupItem: () => void
  onGroupItemImageClick: () => void
  onChangeRoomNumber: (value: number) => void
  onViewDetailSingle: () => void
  onReserveSingle: (bookingType: 'membership' | 'regular') => void
}
const SearchResultGroupItemComp: React.FC<SearchResultGroupItemProps> = ({
  isMultipleSelect,
  canRegularBook,
  groupItem,
  showSubTitle,
  searchMethod,
  onSelectGroupItem,
  onGroupItemImageClick,
  onChangeRoomNumber,
  onViewDetailSingle,
  onReserveSingle,
}) => {
  return (
    <div>
      <GroupItemSm
        isMultipleSelect={isMultipleSelect}
        canRegularBook={canRegularBook}
        groupItem={groupItem}
        showSubTitle={showSubTitle}
        searchMethod={searchMethod}
        onSelectGroupItem={onSelectGroupItem}
        onGroupItemImageClick={onGroupItemImageClick}
        onChangeRoomNumber={onChangeRoomNumber}
        onViewDetailSingle={onViewDetailSingle}
        onReserveSingle={onReserveSingle}
      />
      <GroupItemLg
        isMultipleSelect={isMultipleSelect}
        canRegularBook={canRegularBook}
        groupItem={groupItem}
        showSubTitle={showSubTitle}
        searchMethod={searchMethod}
        onSelectGroupItem={onSelectGroupItem}
        onGroupItemImageClick={onGroupItemImageClick}
        onChangeRoomNumber={onChangeRoomNumber}
        onViewDetailSingle={onViewDetailSingle}
        onReserveSingle={onReserveSingle}
      />
    </div>
  )
}

export default SearchResultGroupItemComp
