'use client'

import classNames from 'classnames'
import { useTranslation } from 'app/i18n/client'
import { formatToYen } from 'utils'
import CopyIcon from 'public/icons/copy.svg'
import ZoomOutIcon from 'public/icons/zoom-out.svg'
import Image from 'next/image'
import HouseIcon from 'public/icons/house-lg.svg'
import UserIcon from 'public/icons/user.svg'
import KidIcon from 'public/icons/kid.svg'
import { Button, Checkbox, Select } from 'app/components/atoms'
import { SearchResultGroupItemProps } from '..'
import { useParams } from 'next/navigation'
import { getRoomOptions } from 'core/bookings'

const GroupItemLg: React.FC<SearchResultGroupItemProps> = ({
  isMultipleSelect,
  canRegularBook,
  groupItem,
  showSubTitle,
  searchMethod,
  onSelectGroupItem,
  onGroupItemImageClick,
  onChangeRoomNumber,
  onViewDetailSingle,
  onReserveSingle,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return (
    <div className="hidden lg:pt-6 lg:flex space-x-6 px-6 items-center">
      <div className="flex-1 ">
        <div
          className={classNames(isMultipleSelect ? 'space-y-2' : 'space-y-4')}
        >
          <div className="text-[22px] font-medium">{groupItem?.name}</div>
          {(showSubTitle || !isMultipleSelect) && (
            <div className="flex">
              <div className="flex-1">
                {showSubTitle ? groupItem?.subTitle : ''}
              </div>
              {!isMultipleSelect && (
                <Button
                  loading={groupItem.isViewingDetail}
                  size="sm"
                  loadingClassName="!text-primary-light"
                  loadingSpinerClassName="!text-primary"
                  className="!bg-primary-light !text-primary !text-xs !px-4 !py-2.5 !w-[96px]"
                  onClick={() => onViewDetailSingle()}
                >
                  {t('Details')}
                </Button>
              )}
            </div>
          )}

          <div className="flex justify-end  divide-x space-x-6">
            <div className="flex items-center">
              <div
                className={classNames(
                  'text-gray-light flex flex-wrap',
                  locale === 'en' ? 'text-sm' : 'space-x-1',
                )}
              >
                <div>{t('Regular price')}</div>
                <div>({t('Including Tax')})</div>
              </div>
              <div className="ml-2 mr-5 text-[28px] text-gray-dark whitespace-nowrap leading-7">
                {formatToYen(groupItem?.regularPrice * groupItem?.roomNumber)}
              </div>
              {!isMultipleSelect && (
                <div className="flex-0">
                  <Button
                    disabled={!canRegularBook}
                    size="sm"
                    loading={groupItem.isBookingRegular}
                    className="!text-xs !px-4 !py-2.5 !w-[96px]"
                    onClick={() => {
                      onReserveSingle('regular')
                    }}
                  >
                    {t('Book now')}
                  </Button>
                </div>
              )}
            </div>
            <div className="pl-6 flex items-center">
              <div
                className={classNames(
                  'text-primary-dark flex flex-wrap',
                  locale === 'en' ? 'text-sm' : 'space-x-1',
                )}
              >
                <div>{t('Membership price')}</div>
                <div>({t('Including Tax')})</div>
              </div>
              <div className="ml-2 mr-5 text-[32px] text-primary-dark whitespace-nowrap leading-8">
                {formatToYen(
                  groupItem?.membershipPrice * groupItem?.roomNumber,
                )}
              </div>
              {!isMultipleSelect && (
                <div className="flex-0">
                  <Button
                    className="!text-xs !px-4 !py-2.5 !w-[96px]"
                    size="sm"
                    loading={groupItem.isBookingMembership}
                    onClick={() => {
                      onReserveSingle('membership')
                    }}
                  >
                    {t('Book now')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {isMultipleSelect && (
            <>
              <div className="flex justify-end">
                <div className="border border-primary-light py-2 px-3 bg-[#F6FBFD] flex items-center">
                  <Checkbox
                    name={`room-plan-${groupItem.id}`}
                    containerSize="md"
                    label={
                      searchMethod === 'plan'
                        ? t('Select Rooms')
                        : t('Select plan')
                    }
                    textClassName="!text-base !leading-4"
                    inputWraperClassName="!items-center"
                    checked={groupItem.selected}
                    onChange={(_) => onSelectGroupItem()}
                  />
                </div>
                <div className="ml-6 flex items-center space-x-2">
                  <div>
                    <HouseIcon />
                  </div>
                  <Select
                    containerClassName="w-[120px] !bg-white"
                    buttonClassName="!py-2.5 !bg-white"
                    placeHolder={t('Select')}
                    options={getRoomOptions(groupItem.maxRooms)}
                    value={groupItem.roomNumber}
                    onChanged={(value) => onChangeRoomNumber(Number(value))}
                    optionListClassName="w-[130px] min-w-full"
                  />
                </div>
                <div className="ml-4 flex items-center space-x-2 space-x-2">
                  <UserIcon />
                  <div className="text-sm text-gray-light">{t('Adult')}</div>
                  <div className="">{groupItem.adultNumber}</div>
                </div>
                <div className="border-l border-gray-150 ml-4 pl-4 flex items-center space-x-2 space-x-2">
                  <KidIcon />
                  <div className="text-sm text-gray-light">{t('Child')}</div>
                  <div className="">{groupItem.childrenNumber}</div>
                </div>
              </div>
            </>
          )}
        </div>
        {isMultipleSelect && (
          <div className="mt-2 text-xxs text-gray-light p-3 bg-[#FAFAFA]">
            {t(
              'Children 6 years old or over will be charged the same fee as adults(excluding some plans).',
            )}
          </div>
        )}
      </div>
      <div className="w-[320px] h-[180px] bg-gray-100 flex-0 relative">
        {groupItem?.images?.[0] && (
          <Image
            alt={t('Room Image')}
            src={groupItem?.images?.[0]}
            fill
            sizes="100vw"
            style={{
              objectFit: 'fill',
            }}
          />
        )}
        {searchMethod === 'rooms' && groupItem?.recommended && (
          <div className="absolute left-0 top-0 bg-white py-1.5 px-2 text-gray-dark text-xxs uppercase w-auto">
            {t('recommended')}
          </div>
        )}
        <div
          className="absolute cursor-pointer flex items-center justify-center bottom-2 right-2 w-8 h-8 bg-white rounded-full"
          onClick={() => onGroupItemImageClick()}
        >
          {searchMethod === 'plan' ? <ZoomOutIcon /> : <CopyIcon />}
        </div>
      </div>
    </div>
  )
}

export default GroupItemLg
