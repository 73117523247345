import qs from 'qs'

import useQueryMembershipDetail from 'app/hooks/useQueryMembershipDetail'
import { useEffect, useState } from 'react'
import useAmenities from 'app/hooks/useAmenities'
import useQueryHotelPlanList from 'app/hooks/useQueryHotelPlanList'
import { RoomSelectInfo } from 'core/bookings/types'
import { SearchResultGroup, SearchResultGroupItem } from '../types'
import getMapper from './getMapper'
import getOrderBy from './getOrderBy'
import {
  AREA_OPTION_ALL,
  DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER,
  HOTEL_OPTION_ALL,
  HOTEL_SEARCH_ORDER_BY_DEFAULT,
  groupRoomSelectList,
} from 'core/bookings'
import useRoomSearchParams from 'app/hooks/useRoomSearchParams'
import { useParams, useRouter } from 'next/navigation'
import useQueryAvailabilitySearch from 'app/hooks/useQueryAvailabilitySearch'
import useQueryHotelList from 'app/hooks/useQueryHotelList'
import { some } from 'lodash'

const useSearchResult = () => {
  const router = useRouter()
  const {
    hotelCode,
    areaId,
    checkIn,
    checkOut,
    amenities,
    numberRoomGuest,
    selectedHotelPlanCodes,
    defaultType: searchMethod,
    orderBy: orderByCondition,
    searchTimestamp,
    defaultNumberRoomGuest,
  } = useRoomSearchParams()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const { data: hotelList } = useQueryHotelList(
    areaId === AREA_OPTION_ALL ? null : (areaId as string | undefined),
  )

  const { data: hotelPlanList } = useQueryHotelPlanList(hotelCode as string)

  const hotelCodes: string[] = !hotelCode
    ? []
    : hotelCode === HOTEL_OPTION_ALL
      ? hotelList
          ?.map((item) => item.triplabotCode || '')
          ?.filter((item) => !!item) || []
      : [hotelCode]

  const {
    isInitialLoading: isLoading,
    isError,
    data: searchResult,
    error,
  } = useQueryAvailabilitySearch(
    hotelCodes,
    checkIn as string,
    checkOut as string,
    amenities as string,
    numberRoomGuest as string,
    searchMethod,
    '',
    null,
    !selectedHotelPlanCodes
      ? undefined
      : Array.isArray(selectedHotelPlanCodes)
        ? selectedHotelPlanCodes
        : ([selectedHotelPlanCodes] as string[] | undefined),
    !!searchTimestamp,
  )

  const { isAuthenticated } = useQueryMembershipDetail()
  const { getAmenities } = useAmenities()

  const [isViewingDetailMultiple, setIsViewingDetailMultiple] = useState(false)
  const [isBookingMultiple, setIsBookingMultiple] = useState(false)

  const getHotelPlanDescription = (code: string) =>
    hotelPlanList?.find((hotelPlan) => hotelPlan.code === code)?.description

  const getHotelByPlanCode = (hotelPlanCode?: string) => {
    const selectedHotelId = hotelPlanList?.find(
      (plan) => plan.code === hotelPlanCode,
    )?.hotelId
    return hotelList?.find((hotel) => hotel.id === selectedHotelId)
  }

  const mapper = getMapper(
    hotelCode,
    getAmenities,
    getHotelPlanDescription,
    getHotelByPlanCode,
    hotelCode === HOTEL_OPTION_ALL,
    locale || 'ja',
  )

  const [groupList, setGroupList] = useState<SearchResultGroup[] | undefined>(
    searchResult && searchMethod
      ? mapper(searchResult, searchMethod)
      : undefined,
  )

  const [canRegularBook, setCanRegularBook] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<
    SearchResultGroup | undefined
  >(undefined)

  const [selectedGroupItem, setSelectedGroupItem] = useState<
    SearchResultGroupItem | undefined
  >(undefined)

  const orderBy = getOrderBy(isAuthenticated)

  useEffect(() => {
    if (searchResult && searchMethod) {
      setGroupList(mapper(searchResult, searchMethod))
    }
  }, [searchResult, searchMethod])

  useEffect(() => {
    setCanRegularBook(!isAuthenticated)
  }, [isAuthenticated])

  const selectedRoomPlanList = groupList
    ?.flatMap((group) => group.items)
    ?.filter((item) => item.selected === true)

  const totalPrice = selectedRoomPlanList?.reduce(
    (total, item) =>
      total +
      ((isAuthenticated ? item.membershipPrice : item.regularPrice) || 0) *
        (item.roomNumber || 0),
    0,
  )

  const totalPriceBeforeTax = selectedRoomPlanList?.reduce(
    (total, item) =>
      total +
      ((isAuthenticated
        ? item.membershipPriceBeforeTax
        : item.regularPriceBeforeTax) || 0) *
        (item.roomNumber || 0),
    0,
  )
  const totalRooms = selectedRoomPlanList?.reduce(
    (total, item) => total + (item.roomNumber || 0),
    0,
  )

  const totalPeople = selectedRoomPlanList?.reduce((total, room) => {
    return (
      total +
      (room.adultNumber || 0) * (room?.roomNumber || 0) +
      (room.childrenNumber || 0) * (room?.roomNumber || 0)
    )
  }, 0)

  const shoudldSwallowError = some(
    (error as any)?.response?.data?.errors,
    (e: any) =>
      some(
        e?.details?.system,
        (s: any) =>
          s.code === 1000 &&
          s.error ===
            '[3-15]1ヶ月より広い範囲の検索期間を指定することは出来ません。',
      ),
  )

  const isMultipleSelect = !!(
    defaultNumberRoomGuest && defaultNumberRoomGuest?.length > 1
  )

  const onSelectGroup = (group: SearchResultGroup) => () => {
    setSelectedGroup(group)
    setSelectedGroupItem(undefined)
  }
  const onUnselectGroup = () => {
    setSelectedGroup(undefined)
    setSelectedGroupItem(undefined)
  }

  const onSelectGroupItem = (groupItem: SearchResultGroupItem) => () => {
    setSelectedGroupItem(groupItem)
    setSelectedGroup(undefined)
  }

  const onUnselectGroupItem = () => {
    setSelectedGroupItem(undefined)
    setSelectedGroup(undefined)
  }

  const onChangeRoomNumber =
    (code: string) => (id: string) => (roomNumber: number) => {
      setGroupList(
        groupList?.map((group) =>
          code !== group.code
            ? group
            : {
                ...group,
                items: group.items.map((item) => ({
                  ...item,
                  roomNumber: item.id === id ? roomNumber : item.roomNumber,
                })),
              },
        ),
      )
    }

  const onDisplayAllItems = (code: string) => () => {
    setGroupList(
      groupList?.map((group) =>
        code !== group.code
          ? group
          : {
              ...group,
              maxItemDisplay: group.items.length,
            },
      ),
    )
  }

  const onDisplayLessItems = (code: string) => () => {
    setGroupList(
      groupList?.map((group) =>
        code !== group.code
          ? group
          : {
              ...group,
              maxItemDisplay: DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER,
            },
      ),
    )

    document
      ?.getElementById(`search-result-group-${code}`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const onSelect = (code: string) => (id: string) => () =>
    setGroupList(
      groupList?.map((group) =>
        code !== group.code
          ? group
          : {
              ...group,
              items: group.items.map((item) => ({
                ...item,
                selected: item.id === id ? !item.selected : item.selected,
              })),
            },
      ),
    )

  const onViewDetailSingle =
    (code: string) => (selectedRoomList: RoomSelectInfo) => () => {
      setGroupList(
        groupList?.map((group) =>
          code !== group.code
            ? group
            : {
                ...group,
                items: group.items.map((item) => ({
                  ...item,
                  isViewingDetail:
                    item.roomPlanCode === selectedRoomList.roomPlanCode,
                })),
              },
        ),
      )
      onViewDetail && onViewDetail([selectedRoomList])
    }

  const onViewDetailMultiple = () => {
    if (
      !selectedRoomPlanList ||
      selectedRoomPlanList.length === 0 ||
      !onViewDetail
    )
      return

    setIsViewingDetailMultiple(true)
    onViewDetail(
      selectedRoomPlanList?.map((item) => ({
        roomPlanCode: item.roomPlanCode,
        roomTypeCode: item?.roomTypeCode,
        hotelPlanCode: item?.hotelPlanCode,
        numberOfAdult: item.adultNumber,
        numberOfChildren: item.childrenNumber,
        numberOfRoom: item?.roomNumber,
        membershipPromotionId: item?.membershipPromotionId,
      })),
    )
  }

  const onReserveSingle =
    (code: string) =>
    (selectedRoomList: RoomSelectInfo) =>
    (bookingType: 'membership' | 'regular') => {
      setGroupList(
        groupList?.map((group) =>
          code !== group.code
            ? group
            : {
                ...group,
                items: group.items.map((item) => ({
                  ...item,
                  isBookingRegular:
                    item.roomPlanCode === selectedRoomList.roomPlanCode &&
                    bookingType === 'regular',
                  isBookingMembership:
                    item.roomPlanCode === selectedRoomList.roomPlanCode &&
                    bookingType === 'membership',
                })),
              },
        ),
      )
      onViewPayment([selectedRoomList], bookingType)
    }

  const onReserveMultiple = (bookingType: 'regular' | 'membership') => () => {
    if (
      !selectedRoomPlanList ||
      selectedRoomPlanList.length === 0 ||
      !onViewPayment
    )
      return

    setIsBookingMultiple(true)
    onViewPayment(
      selectedRoomPlanList?.map((item) => ({
        roomPlanCode: item.roomPlanCode,
        roomTypeCode: item?.roomTypeCode,
        hotelPlanCode: item?.hotelPlanCode,
        numberOfAdult: item.adultNumber,
        numberOfChildren: item.childrenNumber,
        numberOfRoom: item?.roomNumber,
        membershipPromotionId: item?.membershipPromotionId,
      })),
      bookingType,
    )
  }

  const getSelectedHotelCode = (selectedRoomList: RoomSelectInfo[]) => {
    return getHotelByPlanCode(selectedRoomList[0].hotelPlanCode)?.triplabotCode
  }

  const onViewDetail = (selectedRoomList: RoomSelectInfo[]) => {
    if (!areaId || !checkIn || !checkOut) {
      return
    }
    const selectedHotelCode = getSelectedHotelCode(selectedRoomList)
    if (!selectedHotelCode) return

    const urlSearchParams = new URLSearchParams()
    urlSearchParams.set('areaId', areaId)
    urlSearchParams.set('hotelCode', selectedHotelCode)
    if (amenities) {
      urlSearchParams.set('amenities', amenities)
    }
    urlSearchParams.set('numberRoomGuest', numberRoomGuest)
    urlSearchParams.set('checkIn', checkIn)
    urlSearchParams.set('checkOut', checkOut)
    urlSearchParams.set('type', 'plan')
    urlSearchParams.set('orderBy', '')
    urlSearchParams.set(
      'selectedRoomList',
      qs.stringify(groupRoomSelectList(selectedRoomList)),
    )

    if (selectedHotelPlanCodes) {
      urlSearchParams.set('hotelPlanCodes', selectedHotelPlanCodes)
    }

    router.push(`/${locale}/room-plan-detail?${urlSearchParams.toString()}`)
  }

  const onViewPayment = (
    selectedRoomList: RoomSelectInfo[],
    bookingType: 'regular' | 'membership',
  ) => {
    if (!areaId || !checkIn || !checkOut) {
      return
    }
    const selectedHotelCode = getSelectedHotelCode(selectedRoomList)
    if (!selectedHotelCode) return

    const urlSearchParams = new URLSearchParams()
    urlSearchParams.set('areaId', areaId)
    urlSearchParams.set('hotelCode', selectedHotelCode)
    urlSearchParams.set('amenities', amenities)
    urlSearchParams.set('numberRoomGuest', numberRoomGuest)
    urlSearchParams.set('checkIn', checkIn)
    urlSearchParams.set('checkOut', checkOut)
    urlSearchParams.set('type', 'plan')
    urlSearchParams.set('orderBy', HOTEL_SEARCH_ORDER_BY_DEFAULT)
    urlSearchParams.set(
      'selectedRoomList',
      qs.stringify(
        groupRoomSelectList(
          selectedRoomList.map((room) => ({
            ...room,
            membershipPromotionId:
              bookingType === 'membership' ? room.membershipPromotionId : null,
          })),
        ),
      ),
    )
    urlSearchParams.set('bookingType', bookingType)
    if (selectedHotelPlanCodes) {
      urlSearchParams.set('hotelPlanCodes', selectedHotelPlanCodes)
    }
    router.push(`/${locale}/payment?${urlSearchParams.toString()}`)
  }

  return {
    isLoading,
    isError,
    groupList: orderBy(groupList, orderByCondition),
    isViewingDetailMultiple,
    isBookingMultiple,
    totalPrice,
    totalPriceBeforeTax,
    totalRooms,
    totalPeople,
    canRegularBook,
    isAuthenticated,
    selectedGroup,
    selectedGroupItem,
    searchMethod,
    searchTimestamp,
    orderBy: orderByCondition,
    shoudldSwallowError,
    isMultipleSelect,
    onSelectGroup,
    onSelectGroupItem,
    onUnselectGroup,
    onUnselectGroupItem,
    onChangeRoomNumber,
    onSelect,
    onViewDetailSingle,
    onViewDetailMultiple,
    onReserveSingle,
    onReserveMultiple,
    onDisplayAllItems,
    onDisplayLessItems,
  }
}

export default useSearchResult
