'use client'

import SearchHotelForm from './SearchHotelForm'
import useAutoLogin from 'app/hooks/useAutoLogin'
import useAuthenticationRedirect from './useAuthenticationRedirect'
import SelectedPlan from './SelectedPlan'
import SearchResult from './SearchResult'
import { Loading } from 'app/components/atoms'
import useSearchPage from './useSearchPage'

const SearchPage: React.FC = () => {
  useAutoLogin()
  const { shouldRedirect } = useAuthenticationRedirect()

  const {
    selectedPlanName,
    isSearching,
    isOrdering,
    isRemoveSelectedPlan,
    handleSearch,
    handleOrder,
    handleRemoveSelectedPlan,
  } = useSearchPage()

  if (shouldRedirect)
    return (
      <div className="mt-12 md:mt-16 lg:mt-24">
        <Loading className="!text-gray-bg" baseClassName="!text-primary" />
      </div>
    )

  return (
    <>
      <div className="h-full w-full">
        <div>
          <SearchHotelForm isSubmiting={isSearching} onSubmit={handleSearch} />
        </div>
        {isRemoveSelectedPlan === false && selectedPlanName && (
          <SelectedPlan
            selectedPlanName={selectedPlanName}
            onRemoveSelectedPlan={handleRemoveSelectedPlan}
          />
        )}

        <div className="mt-15" id="search-result">
          <SearchResult
            isSearching={isSearching}
            isOrdering={isOrdering}
            onChangeOrder={handleOrder}
            isRemoveSelectedPlan={isRemoveSelectedPlan}
          />
        </div>
      </div>
    </>
  )
}

export default SearchPage
