import React from 'react'
import { Controller } from 'react-hook-form'
import { Select } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import { Control } from 'react-hook-form'
import SelectProps from 'app/components/atoms/Select/Select.props'

interface FormSelectProps extends SelectProps {
  control?: Control<any>
  name: string
  defaultValue?: string
  error?: any
  controllerProps?: any
}

const FormSelect = ({
  controllerProps,
  control,
  name,
  defaultValue,
  error,
  ...rest
}: FormSelectProps) => {
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Select
            {...rest}
            name={name}
            value={value}
            onChanged={(value) => {
              onChange(value)
            }}
            isError={!!error}
            errorMessage={t(error?.message)}
          />
        )
      }}
      name={name}
      defaultValue={defaultValue}
      {...controllerProps}
    />
  )
}

export default FormSelect
