const scrollToElement = (
  elementId: string,
  headerOffset: number | undefined = 100,
  behavior: ScrollBehavior | undefined = 'smooth',
) => {
  const scrollElement = document?.getElementById(elementId)
  if (!scrollElement) return
  const scrollPosition = scrollElement?.getBoundingClientRect().top
  const offsetPosition = scrollPosition + window.scrollY - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior,
  })
}

export default scrollToElement
