'use client'

import { Button } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import PlusIcon from 'public/icons/plus.svg'
import MinusIcon from 'public/icons/minus.svg'

const ToogleShowAllGroupItems: React.FC<{
  searchMethod: 'plan' | 'rooms'
  totalItems: number
  maxItemDisplay: number
  onDisplayLessItems: () => void
  onDisplayAllItems: () => void
}> = ({
  searchMethod,
  totalItems,
  maxItemDisplay,
  onDisplayLessItems,
  onDisplayAllItems,
}) => {
  const { t } = useTranslation()
  const isViewAll = maxItemDisplay === totalItems

  const onClick = () => (isViewAll ? onDisplayLessItems() : onDisplayAllItems())

  return (
    <div className="lg:mt-6 lg:border-t lg:border-gray-150 pt-6 flex justify-center">
      <Button
        className="!bg-white !py-2.5 !px-4 !border !border-primary !text-primary !space-x-2"
        onClick={() => onClick()}
      >
        <div>
          {isViewAll
            ? t('View less')
            : searchMethod === 'plan'
              ? t('Display all rooms ({{n}})', {
                  n: totalItems,
                })
              : searchMethod === 'rooms'
                ? t('Display all plans ({{n}})', {
                    n: totalItems,
                  })
                : ''}
        </div>
        {isViewAll ? <MinusIcon /> : <PlusIcon />}
      </Button>
    </div>
  )
}

export default ToogleShowAllGroupItems
