import { SearchResultModel } from 'core/bookings/types'
import { SearchResultGroup } from '../types'
import {
  DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER,
  getPlanImagesByLocale,
  TOP_HOTEL_SEARCH_RECOMMENDED,
} from 'core/bookings'
import { HotelModel } from 'core/hotels/types'

const getMapper =
  (
    hotelCode: string,
    getAmenities: (
      filters?: {
        id: number
        name: string
      }[],
    ) => string | undefined,
    getPlanDescription: (code: string) => string | undefined,
    getHotelByPlanCode: (planCode: string) => HotelModel | undefined,
    displayHotelName: boolean,
    locale: string,
  ) =>
  (
    searchInfoData?: SearchResultModel | null,
    searchMethod?: 'plan' | 'rooms',
  ) => {
    const searchResultMapper: {
      plan: (
        searchInfoData?: SearchResultModel | null,
      ) => SearchResultGroup[] | undefined
      rooms: (
        searchInfoData?: SearchResultModel | null,
      ) => SearchResultGroup[] | undefined
    } = {
      plan: (searchInfoData?: SearchResultModel | null) =>
        searchInfoData?.plans?.map((plan, index) => ({
          code: plan.code,
          name: plan.name,
          subTitle: getPlanDescription(plan.code),
          images: getPlanImagesByLocale(plan?.urlPlanPictures, locale) || [],
          items: plan?.rooms?.map((room) => ({
            id: `${room?.roomPlanCode}-${room?.matchedSearchCriteria?.adultsCount}-${room?.matchedSearchCriteria?.childrenCount}`,
            hotelCode: hotelCode as string,
            roomPlanCode: room?.roomPlanCode,
            roomTypeCode: room?.roomTypeCode,
            hotelPlanCode: room?.hotelPlanCode,
            name: room?.roomTypeName,
            subTitle: getAmenities(room?.filters),
            membershipPrice:
              room?.signInDiscount?.totalPriceAfterDiscount +
              room?.signInDiscount?.totalPriceAfterDiscountTax,
            membershipPriceBeforeTax:
              room?.signInDiscount?.totalPriceAfterDiscount,
            regularPrice: room?.totalPrice + room?.tax,
            regularPriceBeforeTax: room?.totalPrice,
            maxRooms: room?.roomCount,
            roomNumber: 1,
            adultNumber: room?.matchedSearchCriteria?.adultsCount,
            childrenNumber: room?.matchedSearchCriteria?.childrenCount,
            images: room?.roomPictures,
            membershipPromotionId: room?.signInDiscount?.promotions?.[0]?.id,
            selected: false,
            isViewingDetail: false,
            isBookingRegular: false,
            isBookingMembership: false,
          })),
          maxItemDisplay: DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER,
          recommended: index < TOP_HOTEL_SEARCH_RECOMMENDED ? true : false,
        })),
      rooms: (searchInfoData?: SearchResultModel | null) =>
        searchInfoData?.rooms?.map((room) => ({
          code: room.roomTypeCode,
          name: room.roomTypeName,
          hotelName: displayHotelName
            ? getHotelByPlanCode(room?.plans?.[0]?.hotelPlanCode)?.name
            : undefined,
          subTitle: getAmenities(room?.filters),
          images: room?.roomPictures,
          items: room?.plans?.map((plan, index) => ({
            id: `${plan?.roomPlanCode}-${plan?.matchedSearchCriteria?.adultsCount}-${plan?.matchedSearchCriteria?.childrenCount}`,
            hotelCode: hotelCode as string,
            roomPlanCode: plan?.roomPlanCode,
            roomTypeCode: room?.roomTypeCode,
            hotelPlanCode: plan?.hotelPlanCode,
            name: plan?.roomPlanName,
            subTitle: getPlanDescription(plan?.hotelPlanCode),
            membershipPrice:
              plan?.signInDiscount?.totalPriceAfterDiscount +
              plan?.signInDiscount?.totalPriceAfterDiscountTax,
            membershipPriceBeforeTax:
              plan?.signInDiscount?.totalPriceAfterDiscount,
            regularPrice: plan?.totalPrice + plan?.tax,
            regularPriceBeforeTax: plan?.totalPrice,
            maxRooms: room?.roomCount,
            roomNumber: 1,
            adultNumber: plan?.matchedSearchCriteria?.adultsCount,
            childrenNumber: plan?.matchedSearchCriteria?.childrenCount,
            images: getPlanImagesByLocale(plan?.urlPlanPictures, locale) || [],
            membershipPromotionId: plan?.signInDiscount?.promotions?.[0]?.id,
            selected: false,
            isViewingDetail: false,
            isBookingRegular: false,
            isBookingMembership: false,
            recommended: index < TOP_HOTEL_SEARCH_RECOMMENDED ? true : false,
          })),
          maxItemDisplay: DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER,
        })),
    }

    return searchMethod
      ? searchResultMapper[searchMethod](searchInfoData)
      : undefined
  }

export default getMapper
