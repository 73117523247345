'use client'

import classNames from 'classnames'
import { ImageCarousel, Modal, ModalHeader } from 'app/components/atoms'
import useWindowDimensions from 'app/hooks/useWindowDimensions'
import CrossIcon from 'public/icons/cross.svg'
import { useTranslation } from 'app/i18n/client'
import { MarkdownPreview } from 'react-marked-markdown'

const DetailModal: React.FC<{
  open: boolean
  onClose: () => void
  name?: string
  images?: string[]
  description?: string
  containerClassNames?: string
}> = ({ open, onClose, name, images, description, containerClassNames }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  return (
    <Modal
      position="bottom"
      open={open}
      onClose={onClose}
      containerClassName="!pt-0 !px-0 !pb-0 !w-full !h-full lg:items-center"
    >
      <div
        className={classNames(
          'lg:w-[680px] flex flex-col bg-white shadow-xl transform transition-all lg:align-middle w-full rounded-t-2.5xl lg:rounded-t-none',
          containerClassNames,
        )}
      >
        <div className="hidden lg:block py-6 px-6 border-b border-gray-150">
          <ModalHeader
            onClose={onClose}
            label={<label>{name}</label>}
            labelClassName="text-left"
          />
        </div>
        <div className="hidden lg:block mx-10"></div>
        <div className="border-b border-gray-150 flex pt-6 pb-4 justify-between items-center pr-4 lg:hidden">
          <div className="font-lora text-[22px] pr-16 text-left pl-5 pr-4">
            {name}
          </div>
          <div
            onClick={onClose}
            className="w-8 h-8 flex items-center justify-center"
          >
            <CrossIcon className="fill-current text-gray-light" />
          </div>
        </div>
        <div className={classNames('py-6 px-5 overflow-y-auto text-left')}>
          {images && (
            <ImageCarousel
              name={name}
              images={images}
              mainViewClassNames="lg:!h-[360px]"
              mainViewContainerClassNames="!px-0"
              mainViewStyles={
                width <= 768 ? { height: `${((width - 40) * 9) / 16}px` } : {}
              }
            />
          )}
          {description && (
            <div className="mt-10 space-y-10">
              <div className="text-[32px]">{t('Plan Details')}</div>
              <article className="prose w-full">
                <MarkdownPreview
                  markedOptions={{
                    gfm: true,
                    breaks: true,
                  }}
                  value={description}
                />
              </article>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default DetailModal
