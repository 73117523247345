'use client'

import classNames from 'classnames'
import { useState } from 'react'
import CrossIcon from 'public/icons/cross.svg'

import SelectItem from './SelectItem'
import { Loading, Modal, ModalHeader } from 'app/components/atoms'

interface SelectProps {
  label?: string | React.ReactNode
  placeHolder?: string
  disabled?: boolean
  options: Array<{
    label: string
    value: any
    groupName?: string
  }>
  value?: any
  loading?: boolean
  onChanged?: (value: any) => void
  onClick?: () => void
  containerClassName?: string
  buttonClassName?: string
  ButtonIcon: React.ReactNode
  Icon: React.ReactNode
  selectTitle: string
  isAllAreaOptions?: boolean
  columnNumber?: number
}

const Select: React.FC<SelectProps> = ({
  label,
  placeHolder,
  disabled,
  loading,
  options,
  value,
  containerClassName,
  onChanged,
  onClick,
  Icon,
  ButtonIcon,
  selectTitle,
  isAllAreaOptions,
  columnNumber,
}) => {
  const [showModal, setShowModal] = useState(false)

  const selectedItem = options?.find((x) => x.value === value)

  const onSelect = (value: any) => {
    value !== null &&
      value !== undefined &&
      value !== '' &&
      onChanged &&
      onChanged(value)
    setShowModal(false)
  }

  const groupOptions = options.reduce(
    (result, option) => {
      const groupName = option.groupName || 'DEFAULT'
      const group = result.find((item) => item.groupName === groupName)
      if (!group) {
        return [
          ...result,
          {
            groupName: groupName as string,
            options: [option],
          },
        ]
      }

      return [
        ...result.filter((item) => item.groupName !== groupName),
        {
          groupName: groupName as string,
          options: [...group.options, option],
        },
      ]
    },
    [] as {
      groupName: string
      options: {
        label: string
        value: any
      }[]
    }[],
  )

  const columnsNumber =
    columnNumber || isAllAreaOptions ? (groupOptions.length >= 3 ? 3 : 2) : 1

  return (
    <div className={containerClassName}>
      <div className="relative cursor-pointer border-gray-150 border border-l-2 border-l-primary">
        <div
          className="relative bg-white bg-opacity-40 p-3"
          onClick={() => {
            setShowModal(true)
            onClick && onClick()
          }}
        >
          {label && (
            <label className="block text-xxs lg:text-xs text-gray-50 mb-2 lg:mb-3 uppercase">
              {label}
            </label>
          )}
          <div className="relative">
            <div
              className={classNames(
                'block text-left w-full focus:outline-none text-base text-gray-50 disabled:text-gray-800 flex justify-between pr-10',
                {
                  'opacity-40': loading || disabled,
                },
              )}
            >
              {loading ? (
                <div className="flex-1 flex items-center justify-center py-3.5">
                  <Loading
                    className="!text-white"
                    baseClassName="!text-gray-dark"
                  />
                </div>
              ) : (
                <>
                  <span
                    className={classNames(
                      'truncate text-sm lg:text-lg text-gray-dark',
                    )}
                  >
                    {selectedItem?.label || placeHolder}
                  </span>
                </>
              )}
              <div
                className={classNames(
                  'text-primary absolute inset-y-0 right-0 pr-1 lg:pr-3 flex items-center pointer-events-none',
                )}
              >
                {ButtonIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        position="bottom"
        open={showModal}
        onClose={() => setShowModal(false)}
        containerClassName="!pt-0 !px-0 !pb-0 !w-full !h-full lg:items-center"
      >
        <div
          className={classNames(
            'max-h-[85%] lg:!max-h-full flex flex-col bg-white shadow-xl transform transition-all lg:align-middle w-full lg:h-auto rounded-t-2.5xl lg:rounded-t-none',
            {
              'lg:w-[1008px]': columnsNumber === 2 || columnsNumber === 3,
              'lg:w-[500px]': columnsNumber === 1,
            },
          )}
        >
          <div className="hidden lg:block py-6 px-10 border-b border-gray-150">
            <ModalHeader
              onClose={() => setShowModal(false)}
              label={<label>{selectTitle}</label>}
            />
          </div>
          <div className="hidden lg:block mx-10"></div>
          <div className="border-b border-gray-150 flex pt-6 pb-4 justify-between items-center pr-4 lg:hidden">
            <div className="font-lora text-[22px] truncate pr-16 text-left pl-5 pr-16">
              {selectTitle}
            </div>
            <div
              onClick={() => setShowModal(false)}
              className="w-8 h-8 flex items-center justify-center"
            >
              <CrossIcon className="fill-current text-gray-light" />
            </div>
          </div>
          <div
            className={classNames(
              'py-6 px-5 lg:px-10 px-2 overflow-y-auto grid gap-4',
              columnsNumber === 2 && 'lg:grid-cols-2',
              columnsNumber === 3 && 'lg:grid-cols-3',
            )}
          >
            {groupOptions.length === 1
              ? groupOptions[0].options.map((option) => (
                  <SelectItem
                    key={option.label}
                    option={option}
                    selectedValue={selectedItem?.value}
                    onSelect={onSelect}
                    Icon={Icon}
                  />
                ))
              : groupOptions.map((group, index) => (
                  <div
                    className={classNames('text-left pb-4', {
                      'border-b border-gray-150 lg:border-b-0':
                        groupOptions.length > 1 &&
                        index < groupOptions.length - 1,
                      'lg:border-b lg:border-gray-150':
                        groupOptions.length > 1 &&
                        index < groupOptions.length - columnsNumber,
                    })}
                    key={group.groupName}
                  >
                    <div className="text-base text-gray-light">
                      {group.groupName}
                    </div>
                    <div className="mt-4 space-y-4">
                      {group.options.map((option) => (
                        <SelectItem
                          key={option.label}
                          option={option}
                          selectedValue={selectedItem?.value}
                          onSelect={onSelect}
                          Icon={Icon}
                        />
                      ))}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Select
