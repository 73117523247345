'use client'

import { Select } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import { HOTEL_SEARCH_ORDER_BY } from 'core/bookings'
import { Trans } from 'react-i18next'

const Header: React.FC<{
  searchMethod?: 'rooms' | 'plan'
  totalResult?: number
  orderBy: string
  isOrdering: boolean
  onChangeOrder: (orderBy: string) => void
}> = ({ searchMethod, totalResult, orderBy, isOrdering, onChangeOrder }) => {
  const { t } = useTranslation()

  const getSearchTitle = (type?: 'rooms' | 'plan') =>
    !!type &&
    {
      rooms: t('Available Rooms'),
      plan: t('Available Plans'),
    }[type]

  const orderOptions = [
    {
      label: t('Recommended'),
      value: HOTEL_SEARCH_ORDER_BY.RECOMMENDED,
    },
    {
      label: t('Price Low To High'),
      value: HOTEL_SEARCH_ORDER_BY.PRICE_LOW_TO_HIGH,
    },
    {
      label: t('Price High To Low'),
      value: HOTEL_SEARCH_ORDER_BY.PRICE_HIGH_TO_LOW,
    },
  ]

  return (
    <>
      <div className="hidden w-full lg:flex flex-col md:flex-row justify-between md:items-center">
        <div className="text-[32px]">{getSearchTitle(searchMethod)}</div>
        <div className="mt-3 lg:mt-0 flex space-x-[24px] items-center">
          {totalResult && (
            <div className="flex items-center space-x-1">
              <Trans i18nKey="<0>{{n}}</0><1>Results</1>">
                <div className="text-[32px]">{{ n: totalResult } as any}</div>
                <div className="text-sm text-gray-light">Results</div>
              </Trans>
            </div>
          )}

          <Select
            containerClassName="lg:block md:mt-0 w-full md:w-[320px]"
            placeHolder={t('Sort by')}
            value={orderBy}
            loading={isOrdering}
            options={orderOptions}
            onChanged={onChangeOrder}
            buttonClassName="!border-0"
          />
        </div>
      </div>
      <div className="lg:hidden">
        <div className="flex justify-between">
          <div className="text-[32px]">{getSearchTitle(searchMethod)}</div>
          {totalResult && (
            <div className="flex items-center space-x-1">
              <Trans i18nKey="<0>{{n}}</0><1>Results</1>">
                <div className="text-[32px]">{{ n: totalResult } as any}</div>
                <div className="text-sm text-gray-light">Results</div>
              </Trans>
            </div>
          )}
        </div>
        <Select
          containerClassName="w-full mt-5"
          placeHolder={t('Sort by')}
          value={orderBy}
          loading={isOrdering}
          options={orderOptions}
          onChanged={onChangeOrder}
          buttonClassName="!border-0"
        />
      </div>
    </>
  )
}

export default Header
