import { IListAreaResponse } from 'tokyu-hooks/lib/response/hotel'
import { AreaModel } from './types'

type PlanListItemData = IListAreaResponse['data'][0]

export const toAreaModel = (area: PlanListItemData): AreaModel => ({
  id: area.id,
  translations: area?.translations_attributes?.map((translation) => ({
    locale: translation.locale,
    name: translation.name,
  })),
})
