import React from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'
import { Control } from 'react-hook-form'
import { CheckboxProps } from 'app/components/atoms/Checkbox'

interface FormCheckboxProps extends CheckboxProps {
  control?: Control
  name: string
  defaultValue?: string
  error?: any
  controllerProps?: any
}

const FormCheckbox = ({
  controllerProps,
  control,
  name,
  defaultValue,
  error,
  ...rest
}: FormCheckboxProps) => {
  const { t } = useTranslation()
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          {...rest}
          name={name}
          checked={value}
          onChanged={onChange}
          isError={!!error}
          errorMessage={t(error?.message)}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      {...controllerProps}
    />
  )
}

export default FormCheckbox
