import { Hotel } from 'tokyu-hooks'
import { getClientSession } from 'lib/client/clientSession'
import { toHotelModel } from 'core/hotels'

export const QUERY_HOTEL_LIST_KEY = 'QUERY_HOTEL_LIST_KEY'

const fetchHotels =
  (clientSession?: string) =>
  async ({ queryKey }: { queryKey: any[] }) => {
    const [_key, { areaId, locale }] = queryKey

    const res = await Hotel.listHotels({
      baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
      clientSession: clientSession || (await getClientSession()),
      hotelBrand: process.env.NEXT_PUBLIC_TRIPLA_HOTEL_BRAND as string,
      areaId,
      locale,
    })

    return res?.data?.data?.map(toHotelModel)
  }

export default fetchHotels
