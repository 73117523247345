import {
  IHotelDetailResponse,
  IListHotelsResponse,
  IListPlansResponse,
} from 'tokyu-hooks/lib/response/hotel'
import { HotelDetailModel, HotelModel, HotelPlanModel } from './types'

type HotelData = IListHotelsResponse['data'][0]
type HotelPlanData = IListPlansResponse['data'][0]
type HotelDetailData = IHotelDetailResponse['data']

export const toHotelModel = (hotel: HotelData): HotelModel => ({
  id: hotel.id,
  name: hotel.name,
  hotelCode: hotel.triplabot_code,
  areaName: hotel.area.name,
  triplabotCode: hotel.triplabot_code,
  hotelPhotos: hotel.hotel_photos.map((photo) => ({
    id: photo.id,
    thumbnail: photo.thumbnail,
  })),
})

export const toHotelPlanModel = (hotelPlan: HotelPlanData): HotelPlanModel => ({
  id: hotelPlan?.id,
  name: hotelPlan?.name,
  code: hotelPlan?.code,
  images: hotelPlan?.url_plan_pictures,
})

export const toHotelDetailModel = (
  hotelPlanDetail: HotelDetailData,
): HotelDetailModel =>
  !!hotelPlanDetail
    ? {
        name: hotelPlanDetail.name,
        address: {
          fullAddress: hotelPlanDetail?.address?.full_address,
          city: hotelPlanDetail?.address?.city,
          country: hotelPlanDetail.address.country,
        },
        areaId: hotelPlanDetail?.area?.id,
        latitude: hotelPlanDetail?.latitude,
        longitude: hotelPlanDetail?.longitude,
      }
    : hotelPlanDetail
