import { useParams } from 'next/navigation'
import { RoomItem } from 'tokyu-hooks/lib/api/hotel'
import { DEFAULT_AMENITIES_NAMES } from 'utils/constants'

type Filter = RoomItem['filters']

const useAmenities = () => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const getAmenities = (filters?: Filter) =>
    filters
      ?.filter((amenity) =>
        DEFAULT_AMENITIES_NAMES[locale as 'en' | 'ja'].includes(amenity.name),
      )
      ?.map((amenity) => amenity.name)
      ?.filter((amenity) => !!amenity)
      .join(locale === 'en' ? ', ' : '、')

  return { getAmenities }
}

export default useAmenities
