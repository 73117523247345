'use client'

import useWindowDimensions from 'app/hooks/useWindowDimensions'
import { SearchResultGroup, SearchResultGroupItem } from '../types'
import { useTranslation } from 'app/i18n/client'
import Image from 'next/image'

import CopyIcon from 'public/icons/copy.svg'
import ZoomOutIcon from 'public/icons/zoom-out.svg'

import { MarkdownPreview } from 'react-marked-markdown'
import { take } from 'lodash'
import SearchResultGroupItemComp from './SearchResultGroupItem'
import ToogleShowAllGroupItems from './ToogleShowAllGroupItems'
import { DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER } from 'core/bookings'
import { RoomSelectInfo } from 'core/bookings/types'
import { useEffect, useState } from 'react'

interface SearchResultGroupProps {
  group: SearchResultGroup
  isMultipleSelect?: boolean
  canRegularBook: boolean
  searchMethod: 'rooms' | 'plan'
  onImageClick: () => void
  onGroupItemImageClick: (item: SearchResultGroupItem) => () => void
  onSelectGroupItem: (roomPlanCode: string) => () => void
  onChangeRoomNumber: (roomPlanCode: string) => (value: number) => void
  onViewDetailSingle: (selectedRoomList: RoomSelectInfo) => () => void
  onReserveSingle: (
    selectedRoomList: RoomSelectInfo,
  ) => (bookingType: 'membership' | 'regular') => void
  onDisplayAllItems: () => void
  onDisplayLessItems: () => void
}

const SearchResultGroupComp: React.FC<SearchResultGroupProps> = ({
  group,
  isMultipleSelect,
  canRegularBook,
  searchMethod,
  onImageClick,
  onGroupItemImageClick,
  onSelectGroupItem,
  onChangeRoomNumber,
  onViewDetailSingle,
  onReserveSingle,
  onDisplayAllItems,
  onDisplayLessItems,
}) => {
  const [imageHeight, setImageHeight] = useState(0)
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  useEffect(() => {
    if (width <= 768) {
      setImageHeight(((width - 60) * 9) / 16)
    }
  }, [width])

  return (
    <div
      id={`search-result-group-${group.code}`}
      key={group.code}
      className="bg-white w-full py-6 lg:py-8 px-0 lg:px-8 border-primary-dark lg:border-t-2 before:border-t-2 before:border-primary-dark relative z-[1] lg:z-auto lg:block before:content-[''] before:absolute before:h-full before:bg-white before:-z-1 before:top-0 before:bottom-0 before:-left-5 before:-right-5 lg:before:content-none"
    >
      <div className="lg:flex p-4 lg:p-6 bg-[#FAFAFA] items-center border border-gray-150">
        {group.hotelName && (
          <div className="lg:hidden mb-4 text-[16px] text-primary font-medium">
            {group.hotelName}
          </div>
        )}
        <div className="lg:hidden text-lg font-medium mb-4">{group.name}</div>
        <div
          className="w-full w-auto relative lg:w-[320px] lg:h-[180px] flex-0"
          style={
            width <= 768 && imageHeight > 0
              ? { height: `${imageHeight}px` }
              : {}
          }
        >
          {group?.images?.[0] && (
            <Image
              alt={t('Hotel Image')}
              src={group?.images?.[0]}
              fill
              sizes="100vw"
              style={{
                objectFit: 'fill',
              }}
            />
          )}
          {searchMethod === 'plan' && group?.recommended && (
            <div className="absolute left-0 top-0 bg-white py-1.5 px-2 text-gray-dark text-xxs uppercase w-auto">
              {t('recommended')}
            </div>
          )}
          <div
            className="absolute cursor-pointer flex items-center justify-center bottom-2 right-2 w-8 h-8 bg-white rounded-full"
            onClick={() => onImageClick()}
          >
            {searchMethod === 'plan' ? <CopyIcon /> : <ZoomOutIcon />}
          </div>
        </div>

        <div className="lg:hidden mt-4 prose text-sm w-full line-clamp-3">
          {searchMethod === 'plan' ? (
            <MarkdownPreview
              markedOptions={{
                gfm: true,
                breaks: true,
              }}
              value={group.subTitle || ''}
            />
          ) : (
            <>{group.subTitle}</>
          )}
        </div>
        <div className="lg:ml-6 hidden lg:block flex-1 space-y-6 w-full">
          {group.hotelName && (
            <div className="text-[22px] text-primary font-medium">
              {group.hotelName}
            </div>
          )}
          <div className="text-2xl">{group.name}</div>
          <div className="prose line-clamp-3 w-full">
            {searchMethod === 'plan' ? (
              <MarkdownPreview
                markedOptions={{
                  gfm: true,
                  breaks: true,
                }}
                value={group.subTitle || ''}
              />
            ) : (
              <>{group.subTitle}</>
            )}
          </div>
        </div>
      </div>
      <div className="space-y-6 lg:divide-y lg:divide-gray-150 pt-6 lg:pt-0">
        {take(group?.items, group.maxItemDisplay)?.map((groupItem, index) => (
          <SearchResultGroupItemComp
            groupItem={groupItem}
            key={`${group.code}-${groupItem.id}-${index}`}
            isMultipleSelect={isMultipleSelect}
            canRegularBook={canRegularBook}
            showSubTitle={searchMethod === 'plan'}
            searchMethod={searchMethod}
            onSelectGroupItem={onSelectGroupItem(groupItem.id)}
            onGroupItemImageClick={onGroupItemImageClick(groupItem)}
            onChangeRoomNumber={onChangeRoomNumber(groupItem.id)}
            onViewDetailSingle={onViewDetailSingle({
              roomPlanCode: groupItem.roomPlanCode,
              numberOfAdult: groupItem.adultNumber,
              numberOfChildren: groupItem.childrenNumber,
              numberOfRoom: groupItem.roomNumber,
              membershipPromotionId: groupItem.membershipPromotionId,
              hotelPlanCode: groupItem.hotelPlanCode,
            })}
            onReserveSingle={onReserveSingle({
              roomPlanCode: groupItem.roomPlanCode,
              numberOfAdult: groupItem.adultNumber,
              numberOfChildren: groupItem.childrenNumber,
              numberOfRoom: groupItem.roomNumber,
              membershipPromotionId: groupItem.membershipPromotionId,
              hotelPlanCode: groupItem.hotelPlanCode,
            })}
          />
        ))}
      </div>
      {group.items.length > DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER && (
        <ToogleShowAllGroupItems
          searchMethod={searchMethod}
          totalItems={group.items.length}
          maxItemDisplay={group.maxItemDisplay}
          onDisplayLessItems={onDisplayLessItems}
          onDisplayAllItems={onDisplayAllItems}
        />
      )}
    </div>
  )
}

export default SearchResultGroupComp
