import { IGetSearchFilterResponse } from 'tokyu-hooks/lib/response/hotel'
import { SearchFilterItemModel } from './types'

type SearchFilterData = IGetSearchFilterResponse['data']['search_filters'][0]

export const toSearchFilterModel = (
  data: SearchFilterData,
): SearchFilterItemModel => ({
  id: data?.id,
  name: data?.name,
  order: data?.order,
  type: data?.type,
})
