import { Hotel } from 'tokyu-hooks'
import { getClientSession } from 'lib/client/clientSession'
import { toSearchFilterModel } from 'core/searchFilters'

export const QUERY_SEARCH_FILTER_LIST = 'QUERY_SEARCH_FILTER_LIST'

export const fetchHotelSearchFilterList =
  (clientSession?: string) =>
  async ({ queryKey }: { queryKey: any[] }) => {
    const [_key, { hotelCodes, locale }] = queryKey

    const res = await Hotel.getSearchFilterList({
      baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
      clientSession: clientSession || (await getClientSession()),
      hotelCodes: hotelCodes.filter((code: any) => code),
      locale,
    })

    return res?.data?.search_filters?.map(toSearchFilterModel)
  }
