import axios, { AxiosResponse } from 'axios'

import { setUser } from 'lib/client/user'
import { useTranslation } from 'app/i18n/client'
import { useRouter, useSearchParams } from 'next/navigation'
import { toast } from 'react-toastify'

import useQueryMembershipDetail from '../useQueryMembershipDetail'
import useQueryUserPointDetail from '../useQueryUserPointDetail'

const useAutoLogin = () => {
  const router = useRouter()
  const searchParams = useSearchParams()

  const { t } = useTranslation()

  const { refetch: refetchPointInfo } = useQueryUserPointDetail()
  const { refetch: refetchCurrentUser } = useQueryMembershipDetail()
  const tempToken = searchParams?.get('temporal_token')
  const redirect_to = searchParams?.get('redirect_to')

  const autoLogin = async () => {
    if (!tempToken) return
    try {
      const res: AxiosResponse<{ access_token: string }> = await axios({
        url: `/api/auth/access_token`,
        method: 'get',
        params: {
          temp_token: tempToken as string,
        },
        headers: {
          Accept: 'application/json',
          'App-Version': 'tripla-API/1.0',
          'Content-Type': 'application/json',
        },
      })

      if (res?.data?.access_token) {
        setUser({
          access_token: res?.data?.access_token,
        })
        refetchPointInfo()
        refetchCurrentUser()
      }

      router?.push((redirect_to || '/') as string)
    } catch (e) {
      toast.error(t('Something went wrong'))
    }
  }

  if (typeof window !== 'undefined') {
    autoLogin()
  }
}

export default useAutoLogin
