import { toAreaModel } from 'core/areas'
import { Hotel } from 'tokyu-hooks'

export const QUERY_AREA_LIST_KEY = 'QUERY_AREA_LIST_KEY'

export const fetchAreas = async () => {
  const res = await Hotel.listOfAreas({
    baseUrl: '/',
  })

  return res?.data?.data?.map(toAreaModel)
}
