import { max, min, orderBy } from 'lodash'
import { SearchResultGroup } from '../types'
import { HOTEL_SEARCH_ORDER_BY } from 'core/bookings'

const getOrderBy =
  (isAuthenticated: boolean) =>
  (groupList?: SearchResultGroup[], orderByCondition?: string) => {
    const orderByList = {
      [HOTEL_SEARCH_ORDER_BY.PRICE_HIGH_TO_LOW]: (
        groupList: SearchResultGroup[],
      ) =>
        orderBy(
          groupList,
          (group) =>
            max(
              group?.items?.flatMap((item) =>
                isAuthenticated ? item.membershipPrice : item.regularPrice,
              ),
            ) || 0,
          'desc',
        )?.map((group) => ({
          ...group,
          items: orderBy(
            group.items,
            isAuthenticated ? 'membershipPrice' : 'regularPrice',
            'desc',
          ),
        })),
      [HOTEL_SEARCH_ORDER_BY.PRICE_LOW_TO_HIGH]: (
        groupList: SearchResultGroup[],
      ) =>
        orderBy(
          groupList,
          (plan) =>
            min(
              plan?.items?.flatMap((item) =>
                isAuthenticated ? item.membershipPrice : item.regularPrice,
              ),
            ) || 0,
          'asc',
        )?.map((group) => ({
          ...group,
          items: orderBy(
            group.items,
            isAuthenticated ? 'membershipPrice' : 'regularPrice',
            'asc',
          ),
        })),
      [HOTEL_SEARCH_ORDER_BY.RECOMMENDED]: (groupList: SearchResultGroup[]) =>
        orderBy(groupList, ['recommended'], ['desc']),
    }

    return orderByCondition && orderByList[orderByCondition] && groupList
      ? orderByList[orderByCondition](groupList)
      : groupList
  }

export default getOrderBy
