import { useParams, useRouter } from 'next/navigation'
import qs from 'qs'
import { formatDate } from 'utils'

import useQueryHotelPlanList from 'app/hooks/useQueryHotelPlanList'

import { SearchParams } from 'core/bookings/types'
import useRoomSearchParams from 'app/hooks/useRoomSearchParams'
import { useEffect, useState } from 'react'
import scrollToElement from 'utils/scrollToElement'
import { SearchHotelFormValues } from './SearchHotelForm/types'
import { HOTEL_SEARCH_ORDER_BY_DEFAULT } from 'core/bookings'

const useSearchPage = () => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const [isSearching, setIsSearching] = useState(false)
  const [isOrdering, setIsOrdering] = useState(false)
  const [isRemoveSelectedPlan, setIsRemoveSelectedPlan] = useState(false)

  const router = useRouter()
  const {
    areaId,
    hotelCode,
    checkIn,
    checkOut,
    amenities,
    numberRoomGuest,
    type,
    selectedHotelPlanCodes,
    defaultOrderBy,
    dateRange,
    defaultNumberRoomGuest,
    defaultType,
    defaultAmenities,
    searchTimestamp,
    notScrollToSearchResult,
  } = useRoomSearchParams()

  useEffect(() => {
    setIsSearching(false)
    setIsOrdering(false)
    setIsRemoveSelectedPlan(false)
    if (searchTimestamp && !notScrollToSearchResult) {
      scrollToElement('search-result')
    }
  }, [searchTimestamp])

  const { data: hotelPlanList } = useQueryHotelPlanList(hotelCode as string)

  const selectedPlan = hotelPlanList?.find(
    (plan) => plan.code === selectedHotelPlanCodes,
  )

  const onSearch = (params: SearchParams) => {
    const urlSearchParams = new URLSearchParams()
    urlSearchParams.set('areaId', params.areaId)
    urlSearchParams.set('hotelCode', params.hotelCode)
    urlSearchParams.set('amenities', qs.stringify(params?.amenities))
    urlSearchParams.set(
      'numberRoomGuest',
      qs.stringify(params?.numberRoomGuest),
    )
    urlSearchParams.set('checkIn', formatDate(params.dateRange[0]))
    urlSearchParams.set('checkOut', formatDate(params.dateRange[1]))
    urlSearchParams.set('type', params.type)
    urlSearchParams.set('orderBy', params.orderBy)
    urlSearchParams.set('searchTimestamp', Date.now().toString())
    if (selectedHotelPlanCodes) {
      urlSearchParams.set('hotelPlanCodes', selectedHotelPlanCodes)
    }

    router.push(
      `${params.baseUrl || `/${locale}`}?${urlSearchParams.toString()}`,
    )
  }

  const onRemoveSelectedPlan = () => {
    if (
      !areaId ||
      !hotelCode ||
      !checkIn ||
      !checkOut ||
      !numberRoomGuest ||
      !type ||
      !defaultOrderBy
    ) {
      return
    }

    const urlSearchParams = new URLSearchParams()
    urlSearchParams.set('areaId', areaId)
    urlSearchParams.set('hotelCode', hotelCode)
    urlSearchParams.set('amenities', amenities)
    urlSearchParams.set('numberRoomGuest', numberRoomGuest)
    urlSearchParams.set('checkIn', checkIn)
    urlSearchParams.set('checkOut', checkOut)
    urlSearchParams.set('type', type)
    urlSearchParams.set('orderBy', defaultOrderBy)
    urlSearchParams.set('searchTimestamp', Date.now().toString())

    router.push(`/${locale}/?${urlSearchParams.toString()}`)
  }

  const handleSearch = (values: SearchHotelFormValues) => {
    setIsSearching(true)

    onSearch({
      ...values,
      orderBy: HOTEL_SEARCH_ORDER_BY_DEFAULT,
      searchTimestamp: Date.now().toString(),
    })
  }

  const handleOrder = (value: string) => {
    setIsOrdering(true)

    onSearch({
      areaId,
      hotelCode,
      amenities: defaultAmenities,
      dateRange,
      numberRoomGuest: defaultNumberRoomGuest,
      type: defaultType,
      orderBy: value,
      searchTimestamp: Date.now().toString(),
    })
  }

  const handleRemoveSelectedPlan = () => {
    setIsRemoveSelectedPlan(true)
    onRemoveSelectedPlan()
  }

  return {
    selectedPlanName: selectedPlan?.name,
    isSearching,
    isOrdering,
    isRemoveSelectedPlan,
    handleSearch,
    handleOrder,
    handleRemoveSelectedPlan,
  }
}

export default useSearchPage
