'use client'
import { useEffect } from 'react'
import qs from 'qs'
import { useParams, useRouter, useSearchParams } from 'next/navigation'

const AUTHENTICATION_PAGES = [
  {
    key: 'reservation/confirmation',
    email_update: false,
    transaction: '3d_done',
    path: '/payment-3d-confirmation',
  },
  {
    key: 'reservation/fail',
    email_update: false,
    transaction: undefined,
    path: '/payment-3d-confirmation',
  },
  {
    key: 'member-confirmation',
    email_update: true,
    transaction: undefined,
    path: '/authentication/change-email-confirmation',
  },
  {
    key: 'member-confirmation',
    email_update: false,
    transaction: undefined,
    path: '/authentication/sign-up-confirmation/',
  },
  {
    key: 'change-password',
    email_update: false,
    transaction: undefined,
    path: '/authentication/change-password',
  },
]

const useAuthenticationRedirect = () => {
  const router = useRouter()
  const params = useParams<{ locale: string }>()
  const searchParams = useSearchParams()
  const { locale } = params || {}

  const tripla_booking_widget_open = searchParams?.get(
    'tripla_booking_widget_open',
  )

  const queryParsed = qs.parse(tripla_booking_widget_open) || {}
  const email_update = queryParsed['email_update'] === 'true'
  const transaction = queryParsed['transaction']

  const redirectPath = AUTHENTICATION_PAGES.filter(
    (page) =>
      page.email_update === email_update &&
      (!page.transaction || page.transaction === transaction),
  ).find((page) => tripla_booking_widget_open?.includes(page.key))?.path

  const shouldRedirect = !!redirectPath

  useEffect(() => {
    if (shouldRedirect) {
      router.push(
        `/${locale}${redirectPath}?tripla_booking_widget_open=${encodeURIComponent(
          tripla_booking_widget_open as string,
        )}`,
      )
    }
  }, [shouldRedirect, redirectPath, locale])

  return {
    shouldRedirect,
  }
}

export default useAuthenticationRedirect
